import React, { useState, Fragment, useContext, useEffect } from "react";
import { Table, Input, Tag } from "antd";
import OrderContext from "../../../../context/order/orderContext";
import Filter from "./Filter";
import ShopContext from "../../../../context/shop/shopContext";

const ShopOrderReport = () => {
  const orderContext = useContext(OrderContext);
  const { reportOrders, loadingOrders, clearOrders } = orderContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  // State variables for input values
  const [doorCost, setDoorCost] = useState(30);
  const [buildPercent, setBuildPercent] = useState(8);
  const [roofPercent, setRoofPercent] = useState(2.5);
  const [trussCost, setTrussCost] = useState(3);
  const [paintCost, setPaintCost] = useState(35);
  const [retailPercent, setRetailPercent] = useState(61.25);
  const [overHeadPercent, setOverHeadPercent] = useState(5);

  useEffect(() => {
    if (currentShop) {
      // Update the state variables based on currentShop's values

      setRetailPercent(currentShop.eightTenTwelveNBPercent);
      setOverHeadPercent(currentShop.overhead);
    }
  }, [currentShop]);

  useEffect(() => {
    return () => {
      clearOrders();
    };
  }, []); // Dependency array includes 'location'

  // Function to calculate adjusted shed profit percent
  const calculateAdjustedShedProfitPercent = (order) => {
    // Find the shop values entry for the current shop
    const shopValuesEntry = order?.laborBase?.shopValues?.find((value) => value.shop.toString() === currentShop._id.toString());

    if (!shopValuesEntry) {
      console.warn("Shop values for the current shop not found.");
      return 0; // Return 0 or any appropriate default value in case the shop is not found
    }

    // Extract relevant values from the shop values entry
    const { buildBase, roofBase, trussCount, paintHours } = shopValuesEntry;

    // Calculate individual costs
    const newBuildCost = buildBase * (buildPercent / 100);
    const newRoofCost = roofBase * (roofPercent / 100);
    const newTrussCost = trussCount * trussCost;
    const newPaintCost = paintHours * paintCost;
    const newDoorCost = doorCost;

    // Calculate new total labor cost
    const newTotalLaborCost = newBuildCost + newRoofCost + newTrussCost + newPaintCost + newDoorCost;

    //calculate the shopRetailTotal
    const shopRetailTotal = order?.retailPrice * (retailPercent / 100);

    // Calculate new overhead cost
    const newOverheadCost = shopRetailTotal * (overHeadPercent / 100);

    // Calculate new total cost
    const newTotalCost = order?.shopShedMaterialCost + newTotalLaborCost + newOverheadCost;

    // Calculate adjusted shed profit percent
    const adjustedShedProfitPercent = ((shopRetailTotal - newTotalCost) / shopRetailTotal) * 100;

    return adjustedShedProfitPercent.toFixed(2);
  };

  const columns = [
    {
      title: "Serial #",
      key: "serialNumber",
      width: "30%",
      dataIndex: "serialNumber",
    },
    {
      title: "Shed GP",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record &&
            record?.shedProfitPercent?.shop.toLocaleString("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
            })}
        </Fragment>
      ),
    },
    {
      title: "Adjusted Shed Profit Percent",
      align: "center",
      render: (text, record, rowIndex) => <Fragment>{reportOrders && calculateAdjustedShedProfitPercent(record) + "%"}</Fragment>,
    },
    {
      title: "Status",
      width: "8%",
      align: "center",
      render: (text, record, rowIndex) => (
        <Fragment>
          <Tag className="" color="volcano">
            {record && record?.status}
          </Tag>
        </Fragment>
      ),
    },
  ];

  return (
    <Fragment>
      <div className="row mb-4">
        <div className="col-md-6">
          <Filter />
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-12">
          <h4>Adjust Shed Labor and Overhead Inputs</h4>
          <div className="row">
            <div className="col-md-3">
              <label>Door Cost</label>
              <Input
                type="number"
                placeholder="Door Cost"
                value={doorCost}
                onChange={(e) => setDoorCost(Number(e.target.value))}
              />
            </div>
            <div className="col-md-3">
              <label>Build Percent</label>
              <Input
                type="number"
                placeholder="Build Percent"
                value={buildPercent}
                onChange={(e) => setBuildPercent(Number(e.target.value))}
              />
            </div>
            <div className="col-md-3">
              <label>Roof Percent</label>
              <Input
                type="number"
                placeholder="Roof Percent"
                value={roofPercent}
                onChange={(e) => setRoofPercent(Number(e.target.value))}
              />
            </div>
            <div className="col-md-3">
              <label>Truss Cost</label>
              <Input
                type="number"
                placeholder="Truss Cost"
                value={trussCost}
                onChange={(e) => setTrussCost(Number(e.target.value))}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3">
              <label>Paint Cost</label>
              <Input
                type="number"
                placeholder="Paint Cost"
                value={paintCost}
                onChange={(e) => setPaintCost(Number(e.target.value))}
              />
            </div>
            <div className="col-md-3">
              <label>Retail Percent</label>
              <Input
                type="number"
                placeholder="Retail Percent"
                value={retailPercent}
                onChange={(e) => setRetailPercent(Number(e.target.value))}
              />
            </div>
            <div className="col-md-3">
              <label>Overhead Percent</label>
              <Input
                type="number"
                placeholder="Overhead Percent"
                value={overHeadPercent}
                onChange={(e) => setOverHeadPercent(Number(e.target.value))}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        pagination={false}
        loading={!loadingOrders && reportOrders ? false : true}
        columns={columns}
        dataSource={reportOrders && reportOrders}
        rowKey="_id"
      />
    </Fragment>
  );
};

export default ShopOrderReport;
