import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import AddOnContext from "../../../../../../context/addon/addOnContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import AuthContext from "../../../../../../context/auth/authContext";

import "antd/dist/antd.css";
import { Table, Button } from "antd";

const AllAddons = () => {
  const navigate = useNavigate();

  const addOnContext = useContext(AddOnContext);
  const addonCategoryContext = useContext(AddonCategoryContext);

  const { currentAddonCategory } = addonCategoryContext;
  const { getAddOns, addOns, setCurrentAddOn, importAddons, deleteAddOn, updateAllAddons } = addOnContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (currentAddonCategory && currentShop) {
      setLoading(true);
      getAddOns(currentAddonCategory._id, currentShop._id).finally(() => setLoading(false));
    }
  }, [currentAddonCategory, currentShop]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  addOns &&
    addOns.map((addOn) => {
      let addOnItem = {};
      addOnItem.id = addOn._id;
      addOnItem.Component_Name = addOn.name;

      addOn.standardItems &&
        addOn.standardItems.map((standardItem, index) => {
          addOnItem[`ID_${index + 1}`] = standardItem.item._id;
          addOnItem[`Name_${index + 1}`] = standardItem.item.name;
          addOnItem[`Quantity_${index + 1}`] = standardItem.quantity;
          // addOnItem[`Replace_Item_${index + 1}`] = standardItem.replaceItem;
          // addOnItem[`Replacement_Item_${index + 1}`] = standardItem.replacementItem?._id;
        });

      itemsForExport.push(addOnItem);
    });

  // The function to export the file
  // The function to export the file
  const exportFile = () => {
    // Create a worksheet from JSON data
    const ws = utils.json_to_sheet(itemsForExport);

    // Get the headers (keys) of the first object in the list to determine the column structure
    const headers = Object.keys(itemsForExport[0]);

    // Apply column widths to hide the "ID" columns
    const colWidths = headers.map((header) => {
      if (header.toLowerCase().includes("id")) {
        return { hidden: true }; // Hide columns containing "ID"
      } else {
        return { wch: 20 }; // Default width for other columns
      }
    });

    // Assign the column configurations to the worksheet
    ws["!cols"] = colWidths;

    // Create a new workbook and append the worksheet
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");

    // Write the workbook to a file
    writeFileXLSX(wb, `ShedPro_${currentAddonCategory.name}.xlsx`);
  };

  const importFile = () => {
    importAddons(newFile);
  };

  const uploadFile = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const handleDeleteAddon = (addon) => {
    deleteAddOn(addon._id);
  };

  const columns = [
    // {
    //   title: "Name",
    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewAddon(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record.retailPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Fragment>
      ),
    },
    {
      title: "Standard Cost",
      render: (record) => (
        <Fragment>
          {record.addOnStandardTotalCost &&
            record.addOnStandardTotalCost?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Gross Profit",
      render: (record) => (
        <Fragment>
          {record.standardAddOnGrossProfit &&
            record.standardAddOnGrossProfit?.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Standard Gross Profit %",
      render: (text, record, rowIndex) => (
        <Fragment>
          {record.standardAddOnGrossProfitPercent?.toLocaleString("en-US", {
            style: "percent",
            minimumFractionDigits: 2,
          })}
        </Fragment>
      ),
    },
    // {
    //   title: "Edit",
    //   width: "5%",
    //   render: (text, record) => (
    //     <span>
    //       <Button type="primary" size="small" onClick={() => handleViewAddon(record)}>
    //         View
    //       </Button>
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddAddon = () => {
    navigate("/company/addon/form");
  };

  const handleViewAddon = (addon) => {
    setCurrentAddOn(addon);
    navigate("/company/addon/form");
  };

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const importUpdateFile = () => {
    updateAllAddons(newFile);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <Button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  onClick={handleAddAddon}
                >
                  Add Add-on
                </Button>
                <Button type="danger" className="mb-2 float-end" onClick={handleBack}>
                  Back
                </Button>
                {addOns && (
                  <Button
                    className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info float-end"}
                    disabled={user.viewOnly}
                    onClick={exportFile}
                  >
                    Download BOM
                  </Button>
                )}
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              loading={loading}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={addOns}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
          </div>
          <div className="col-md-4">
            <Button
              className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info"}
              disabled={user.viewOnly}
              onClick={importUpdateFile}
            >
              Update Addons
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllAddons;
