import React, { Fragment } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Checkbox, Input, Button, Row, Col, Card, Typography } from "antd";

const { Title } = Typography;

const Items = ({ item, itemIndex, control, remove, setValue, getValues, itemOptions, register }) => {
  const setItemCount = (quantity) => {
    setValue(`standardItems[${itemIndex}].quantity`, quantity);
  };

  const setItemDescription = (description) => {
    setValue(`standardItems[${itemIndex}].description`, description);
  };

  const setItemChange = (e) => {
    setValue(`standardItems[${itemIndex}].item`, e);
    setValue(`standardItems[${itemIndex}].description`, "");
    setValue(`standardItems[${itemIndex}].quantity`, 0);
  };

  const productOptions =
    itemOptions &&
    itemOptions.map((item) => {
      const actualItem = item.item ? item.item : item;
      return { label: actualItem.name, value: actualItem };
    });

  return (
    <Card
      style={{ marginBottom: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}
    >
      <Row gutter={8} align="middle">
        <Col span={8}>
          <Controller
            render={({ field }) => (
              <ReactSelect
                {...field}
                options={productOptions}
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                value={(field.value && { label: field.value.name, value: field.value }) || null}
                onChange={(e) => setItemChange(e.value)}
              />
            )}
            key={item.id}
            name={`standardItems[${itemIndex}].item`}
            control={control}
          />
        </Col>
        <Col span={8}>
          <Controller
            render={({ field, name }) => (
              <Input
                type="string"
                name={name}
                {...field}
                onChange={(e) => {
                  const description = e.target.value;
                  setItemDescription(description);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`standardItems[${itemIndex}].description`}
          />
        </Col>
        <Col span={4}>
          <Controller
            render={({ field, name }) => (
              <Input
                type="number"
                step={0.01}
                name={name}
                {...field}
                onChange={(e) => {
                  const quantity = e.target.value;
                  setItemCount(quantity);
                }}
              />
            )}
            key={item.id}
            control={control}
            name={`standardItems[${itemIndex}].quantity`}
          />
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Button
            type="danger"
            onClick={() => {
              remove(itemIndex);
            }}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default function Fields({ control, register, setValue, getValues, allItems }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "standardItems",
  });

  return (
    <div className="container-fluid">
      <Row justify="center">
        <Col span={24}>
          <Title level={4} className="text-center">
            Standard Items
          </Title>
        </Col>
      </Row>
      <Row gutter={8} align="middle" style={{ marginBottom: "8px" }}>
        <Col span={8} className="text-center">
          <strong>Item Name</strong>
        </Col>
        <Col span={8} className="text-center">
          <strong>Description</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Count</strong>
        </Col>
        <Col span={2} className="text-center">
          <strong>Actions</strong>
        </Col>
      </Row>
      {fields.map((item, itemIndex) => (
        <Items
          key={item.id}
          item={item}
          itemIndex={itemIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          itemOptions={allItems}
        />
      ))}
      <Row justify="center">
        <Col>
          <Button
            type="dashed"
            onClick={(e) => {
              e.preventDefault();
              append({ value: "0" });
            }}
          >
            Add Item
          </Button>
        </Col>
      </Row>
    </div>
  );
}
