import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../../../../context/shop/shopContext";
import ItemContext from "../../../../../../context/item/itemContext";
import ItemCategoryContext from "../../../../../../context/itemCategory/itemCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";
import Select from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { read, utils, writeFileXLSX } from "xlsx";

const ItemAllShops = () => {
  const navigate = useNavigate();

  const shopContext = useContext(ShopContext);
  const { getShops, shops, setCurrentShop } = shopContext;

  const itemContext = useContext(ItemContext);
  const { updateAllShops } = itemContext;

  const itemCategoryContext = useContext(ItemCategoryContext);
  const { getItemCategorys, itemCategorys } = itemCategoryContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    getShops();
    getItemCategorys();
  }, []);

  useEffect(() => {}, [shops]);

  const columns = [
    // {
    //   title: "Name",
    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewAddonsShedCategory(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },

    // {
    //   title: "Edit",
    //   width: "10%",
    //   render: (text, record) => (
    //     <span>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonsShedCategory(record)}>
    //         View
    //       </button>
    //       {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedCategory(record)}>
    //         Edit
    //       </button> */}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddonsShedCategory = (shedCategory) => {
    setCurrentShop(shedCategory);
    navigate("/company/items/shop/all");
  };

  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };
  const [newFile, setNewFile] = useState("");

  // const importFile = () => {
  //   updateAllShops(shopsToUpdate, newFile);
  // };

  // const uploadFile = (e) => {
  //   // const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = read(data, { type: "array" });
  //     // Initialize an empty array to hold all items
  //     let allItems = [];
  //     // Iterate over all sheets in the workbook
  //     workbook.SheetNames.forEach((sheetName) => {
  //       const worksheet = workbook.Sheets[sheetName];
  //       const json = utils.sheet_to_json(worksheet);
  //       // Append items from this sheet to the allItems array
  //       allItems = [...allItems, ...json];
  //     });
  //     setNewFile(allItems);
  //   };
  //   reader.readAsArrayBuffer(e.target.files[0]);
  // };
  const [workbook, setWorkbook] = useState(null); // Store the workbook for later processing
  const [sheetNames, setSheetNames] = useState([]); // Store sheet names for filtering

  const uploadFile = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const loadedWorkbook = read(data, { type: "array" });

      // Save the workbook and sheet names
      setWorkbook(loadedWorkbook);
      setSheetNames(loadedWorkbook.SheetNames);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const importFile = () => {
    if (!workbook) {
      console.error("No workbook available. Please upload a file first.");
      alert("Please upload a file before importing.");
      return;
    }

    const selectedCategories = itemCategoriesToUpdate.map((category) => category.label);

    // Determine which sheets to process
    const sheetsToProcess =
      selectedCategories.length > 0
        ? sheetNames.filter((name) => selectedCategories.includes(name)) // Filter by selected categories
        : sheetNames; // Process all sheets if no categories are selected

    let allItems = [];
    sheetsToProcess.forEach((sheetName) => {
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      allItems = [...allItems, ...json];
    });

    updateAllShops(shopsToUpdate, allItems);
    // console.log("Imported Data:", allItems);

    // Optional: Provide feedback
    alert(`Successfully imported ${allItems.length} records from ${sheetsToProcess.length} sheets.`);
  };

  const shopOptions =
    shops &&
    shops.map((shop) => ({
      value: shop._id,
      label: shop.name,
    }));

  const itemCategoryOptions =
    itemCategorys &&
    itemCategorys.map((itemCategory) => ({
      value: itemCategory._id,
      label: itemCategory.name,
    }));

  const [shopsToUpdate, setShopsToUpdate] = useState([]);

  const [itemCategoriesToUpdate, setItemCategoriesToUpdate] = useState([]);

  const handleShopChange = (e) => {
    console.log("This is shopsToUpdate", e);
    setShopsToUpdate(e);
  };

  const handleItemCategoryChange = (e) => {
    console.log("This is itemCategoriesToUpdate", e);
    setItemCategoriesToUpdate(e);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-3"></div>
        <div className="col-md-3">
          <p>If no Category is selected, will update all categories.</p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>
        <div className="col-md-3">
          <Select
            isMulti
            placeholder="Select Shops"
            name="shops"
            options={shopOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            //   value={zones}
            onChange={handleShopChange}
          />
        </div>
        <div className="col-md-3">
          <Select
            isMulti
            placeholder="Select Item Categories"
            name="itemCategories"
            options={itemCategoryOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            //   value={zones}
            onChange={handleItemCategoryChange}
          />
        </div>
        <div className="col-md-2">
          <button
            // className="mb-2 me-2 btn btn-primary"
            className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-primary"}
            // disabled={user.viewOnly}
            disabled={user.viewOnly || !shopsToUpdate || shopsToUpdate.length === 0}
            onClick={() => importFile()}
          >
            Import Excel
          </button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        // loading={!loadingProduct && productResults ? false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={shops}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ItemAllShops;
