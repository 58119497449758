import React, { useEffect, Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../../context/shop/shopContext";
import { Table, Modal } from "antd";
import AuthContext from "../../../../context/auth/authContext";

const ShopColumns = ({ shops, filteredShop }) => {
  const navigate = useNavigate();

  const shopContext = useContext(ShopContext);
  const { setCurrentShop, deleteShop, clearCurrentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const handleRemove = (id) => {
    Modal.confirm({
      title: "Are you sure you want to delete this Shop?",
      okText: "Yes",
      okType: "danger",
      onOk: () => {
        deleteShop(id);
        // clearMaterialOrders();
        // getMaterialOrders();
      },
    });
  };

  const handleEdit = (shop) => {
    setCurrentShop(shop);
    navigate(`/company/shop/form`);
  };

  const handleAddShop = () => {
    clearCurrentShop();
    navigate(`/company/shop/form`);
  };

  const columns = [
    // {
    //   title: "Name",
    //   render: (text, record) => {
    //     // do something like adding commas to the value or prefix
    //     return <span>{record.name}</span>;
    //   },
    // },

    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleEdit(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },

    {
      title: "City",
      render: (text, record) => {
        return <span>{record.city}</span>;
      },
    },

    {
      title: "State",
      render: (text, record) => {
        return <span>{record.state}</span>;
      },
    },
    {
      title: "Id",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record._id}</span>;
      },
    },
    // {
    //   title: "Actions",
    //   fixed: "right",
    //   width: "10%",
    //   render: (text, record) => (
    //     <Fragment>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleEdit(record)}>
    //         View
    //       </button>
    //       {/* <button className="btn btn-xs btn-outline-danger" type="button" onClick={() => handleRemove(record._id)}>
    //         Delete
    //       </button> */}
    //     </Fragment>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {/* <button className='btn btn-outline-info' onClick={handleTest}>
                  Do not use
                </button> */}
              <div className="col-md-10">{/* <ShopFilter /> */}</div>

              <div className="col-md-2">
                {user && user.superAdmin && (
                  <button className="btn btn-outline-secondary float-end" type="button" onClick={() => handleAddShop()}>
                    Add Shop
                  </button>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <Table
                  rowClassName={() => "hover-row"}
                  pagination={false}
                  loading={shops ? false : true}
                  columns={columns}
                  dataSource={filteredShop && filteredShop !== null ? filteredShop : shops && shops}
                  rowKey="_id"
                  bordered={true}
                  onChange={onChange}
                  scroll={{ y: "70vh" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ShopColumns;
