import React, { Fragment, useState, useContext } from "react";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Modal, Card, Button, Row, Col, Typography } from "antd";
import ShopComponentForm from "../../components/component/ShopComponentForm";
import ComponentContext from "../../../../../../context/component/componentContext";
import AuthContext from "../../../../../../context/auth/authContext";

const { Title } = Typography;

const Components = ({ component, componentIndex, control, remove, setValue, getValues, register }) => {
  const setComponentCount = (count) => {
    setValue(`components[${componentIndex}].quantity`, count);
  };

  const componentContext = useContext(ComponentContext);
  const { setCurrentComponent } = componentContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);

  // const viewComponent = () => {
  //   const newComponent = component.component;
  //   const filteredShopItems = newComponent.shopItems.filter((shopItem) => shopItem.shop === user.shop);
  //   const itemsArray = filteredShopItems.map((shopItem) => shopItem.items).flat();
  //   const updatedComponent = {
  //     ...newComponent,
  //     shopItems: itemsArray,
  //   };
  //   setCurrentComponent(updatedComponent);
  //   setIsModalVisible(true);
  // };

  const viewComponent = () => {
    const newComponent = component.component;
    setCurrentComponent(newComponent);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // const theComponentOptions =
  //   components &&
  //   components.map((component) => {
  //     return { label: `${component.name}`, value: component };
  //   });

  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: "12.5%" }}
        width="75vw"
        bodyStyle={{ height: "75vh", overflow: "auto", maxWidth: "100%" }}
        footer={null}
      >
        <ShopComponentForm setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
      </Modal>
      <Card
        style={{
          marginBottom: "8px",
          boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)",
          border: "1px solid #e8e8e8",
          borderRadius: "8px",
        }}
      >
        <Row gutter={8}>
          <Col span={16}>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={(field.value && { label: field.value.name, value: field.value }) || null}
                  isDisabled={true}
                  isClearable={true}
                  // options={theComponentOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setValue(`components[${componentIndex}].component`, e.value);
                    setValue(`components[${componentIndex}].quantity`, 0);
                  }}
                />
              )}
              key={component.id}
              name={`components[${componentIndex}].component`}
              control={control}
            />
          </Col>
          <Col span={4}>
            <Controller
              render={({ field, name }) => (
                <input
                  readOnly
                  className="form-control inputbg"
                  type="number"
                  name={name}
                  {...field}
                  onChange={(e) => {
                    const count = e.target.value;
                    setComponentCount(count);
                  }}
                />
              )}
              key={component.id}
              control={control}
              name={`components[${componentIndex}].quantity`}
            />
          </Col>
          <Col span={4} className="text-end">
            <Button type="primary" onClick={viewComponent}>
              View
            </Button>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, components }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "components",
  });

  return (
    <div className="container-fluid">
      {fields.length > 0 && (
        <Row className="mt-2">
          <Col span={16} className="text-center">
            <Title level={5}>Component Name</Title>
          </Col>
          <Col span={4} className="text-center">
            <Title level={5}>Count</Title>
          </Col>
          <Col span={4} className="text-end">
            <Title level={5}>View</Title>
          </Col>
        </Row>
      )}

      {fields.map((component, componentIndex) => (
        <Components
          key={component.id}
          component={component}
          componentIndex={componentIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          // components={components}
        />
      ))}
    </div>
  );
}
