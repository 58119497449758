import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";
import ReactSelect from "react-select";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllComponents = () => {
  const navigate = useNavigate();
  const componentContext = useContext(ComponentContext);
  const {
    getComponents,
    components,
    filteredComponents,
    loadingComponent,
    setCurrentComponent,
    clearCurrentComponent,
    updateAllComponents,
    clearComponents,
    deleteComponent,
    updateSelectedCategoriesComponents,
  } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory, shedCategorys, getShedCategorys } = shedCategoryContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    clearCurrentComponent();
    getShedCategorys();
    getComponents(currentShedCategory._id, currentComponentCategory._id, currentShop._id);
    // clear components with cleanup
    return () => {
      clearComponents();
    };
  }, []);

  useEffect(() => {}, [components]);

  const shedCategoryOptions =
    shedCategorys && shedCategorys.map((shedCategory) => ({ value: shedCategory._id, label: shedCategory.name }));

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  components &&
    components.map((component) => {
      let componentItem = {};
      // if the user.name === "Matt Schmidt" show the shed
      if (user.name === "Matt Schmidt") {
        componentItem.ShedCategory = component.shedCategory;
        componentItem.Category = component.category;
        componentItem.ID = component._id;
      }
      // componentItem.ShedCategory = component.shedCategory;
      // componentItem.Category = component.category;
      // componentItem.ID = component._id;
      componentItem.Name = component.name;

      component.standardItems &&
        component.standardItems.map((standardItem, index) => {
          // componentItem[`Standard_Item_${index + 1}`] = standardItem.item._id;
          componentItem[`Name_${index + 1}`] = standardItem.item.name;
          if (user.name === "Matt Schmidt") {
            componentItem[`ID_${index + 1}`] = standardItem.item._id;
          }
          componentItem[`Description_${index + 1}`] = standardItem.description;
          componentItem[`Count_${index + 1}`] = standardItem.quantity;
        });

      itemsForExport.push(componentItem);
    });

  const exportFile = () => {
    const ws = utils.json_to_sheet(itemsForExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `ShedPro_${currentShedCategory.name}_${currentComponentCategory.name}_Components_BOM.xlsx`);
  };

  const importFile = () => {
    updateAllComponents(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    // {
    //   title: "Name",
    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewComponent(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Id",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record._id}</span>;
      },
    },
    {
      title: "Standard Cost",
      render: (record) => (
        <Fragment>
          {record.componentStandardTotalCost &&
            record.componentStandardTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    // {
    //   title: "Shop Cost",
    //   render: (record) => (
    //     <Fragment>
    //       {record.componentShopTotalCost &&
    //         record.componentShopTotalCost.toLocaleString("en-US", {
    //           style: "currency",
    //           currency: "USD",
    //         })}
    //     </Fragment>
    //   ),
    // },

    // {
    //   title: "Edit",
    //   width: "5%",
    //   render: (text, record) => (
    //     <span>
    //       {components && (
    //         <Fragment>
    //           <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewComponent(record)}>
    //             View
    //           </button>
    //           <button className="btn btn-xs btn-outline-danger mt-2" type="button" onClick={() => handleDeleteComponent(record)}>
    //             Delete
    //           </button>
    //         </Fragment>
    //       )}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddComponent = () => {
    navigate("/company/component/form");
  };

  const handleViewComponent = (component) => {
    setCurrentComponent(component);
    navigate("/company/component/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteComponent = (component) => {
    deleteComponent(component._id);
  };

  const [selectedShedCategories, setselectedShedCategories] = useState([]);

  const handleShedCategoryChange = (selectedShedCategories) => {
    setselectedShedCategories(selectedShedCategories);
  };

  const updateCategories = () => {
    const shedCategorieIds = selectedShedCategories.map((shedCategory) => shedCategory.value);
    updateSelectedCategoriesComponents(currentShedCategory._id, currentComponentCategory._id, shedCategorieIds);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddComponent()}
                >
                  Add Component
                </button>
                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
                {components && (
                  <button
                    className={user.viewOnly ? "viewOnly" : "mb-2 me-2 btn btn-outline-info float-end"}
                    disabled={user.viewOnly}
                    // className="mb-2 me-2 btn btn-outline-info float-end"
                    onClick={() => exportFile()}
                  >
                    DownLoad Components BOM
                  </button>
                )}
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              loading={components ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={filteredComponents && filteredComponents !== null ? filteredComponents : components && components}
              // dataSource={testModels}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
        <div className="row">
          <p>Use the above componets to mass update the same components in Categories that you select. (Items only)</p>
          <div className="col-md-6">
            {/* <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} /> */}
            {/* add a reaqct-select here */}

            <ReactSelect
              options={shedCategoryOptions}
              onChange={handleShedCategoryChange}
              value={selectedShedCategories}
              isMulti
              isSearchable
              placeholder="Select Shed Categories"
              // className="basic-multi-select"
              // classNamePrefix="select"
            />
          </div>
          <div className="col-md-4">
            {/* <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
              Upload Updated BOM
            </button> */}
            <button className="m-2s btn btn-outline-info" onClick={() => updateCategories()}>
              Update
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllComponents;
