import React, { useState, useContext, useEffect } from "react";
import { Modal, Tag, DatePicker } from "antd";
import Select from "react-select";
import ShopContext from "../../../../context/shop/shopContext";
import ShedModelContext from "../../../../context/shedModel/shedModelContext";
// import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import OrderContext from "../../../../context/order/orderContext";
import moment from "moment";

const allOrderTypes = [
  { value: "Customer", label: "Customer" },
  { value: "Inventory", label: "Inventory" },
];
const allOrderDates = [
  { value: "Date Ordered", label: "Date Ordered" },
  { value: "Date Finished", label: "Date Finished" },
];
const allBuildingStages = [
  { value: "New", label: "New" },
  { value: "InQueue", label: "InQueue" },
  { value: "On Hold", label: "On Hold" },
  { value: "Building", label: "Building" },
  { value: "Finished", label: "Finished" },
  { value: "Invoiced", label: "Invoiced" },
  { value: "Canceled", label: "Canceled" },
];

function FilterComponent({}) {
  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;
  const shedModelContext = useContext(ShedModelContext);
  const { getShedModels, shedModels } = shedModelContext;
  const orderContext = useContext(OrderContext);
  const { getReportOrders } = orderContext;
  // const shedCategoryContext = useContext(ShedCategoryContext);
  // const { shedCategorys, getShedCategorys } = shedCategoryContext;

  useEffect(() => {
    currentShop && getShedModels(currentShop?.defaultCategory?._id);
    // getShedCategorys();
  }, [currentShop]);

  let modelOptions = shedModels && shedModels.map((model) => ({ value: model._id, label: model.name }));
  // let categoryOptions = shedCategorys && shedCategorys.map((category) => ({ value: category._id, label: category.name }));
  // let shop = { label: currentShop.name, value: currentShop._id };

  const [filters, setFilters] = useState({
    orderType: [],
    buildingStages: [],
    shops: currentShop ? [{ label: currentShop?.name, value: currentShop?._id }] : [],
    categories: [],
    models: [],
    dates: [moment().startOf("month").subtract(1, "months"), moment().endOf("month").subtract(1, "months")], // Set initial date range to last month
    orderDate: allOrderDates[1],
  });

  useEffect(() => {
    // const formattedFilters = {
    //   ...filters,
    //   dates: filters.dates.map((date) => date.format("MM-DD-YYYY")),
    // };
    getReportOrders(filters);
  }, [filters]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    // const formattedFilters = {
    //   ...filters,
    //   dates: filters.dates.map((date) => date.format("MM-DD-YYYY")),
    // };
    // getReportOrders(formattedFilters);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilterChange = (filterKey, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const clearFilter = (filterType, filterValue) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (Array.isArray(newFilters[filterType])) {
        newFilters[filterType] = newFilters[filterType].filter((value) => value.value !== filterValue.value);
      } else {
        newFilters[filterType] = null;
      }
      getReportOrders(newFilters);
      return newFilters;
    });
  };

  return (
    <div>
      <button className="btn btn-primary" onClick={showModal}>
        Select one or more filters
      </button>

      <Modal title="Select Filters" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <DatePicker.RangePicker
          format="MM/DD/YYYY"
          className="mb-4 custom-datepicker"
          onChange={(value) => handleFilterChange("dates", value)}
          value={filters.dates}
        />
        <Select
          className="mb-4"
          options={allOrderDates}
          placeholder="Date Ordered"
          onChange={(value) => handleFilterChange("orderDate", value)}
          // isMulti
          // isClearable
          value={filters.orderDate}
        />
        {/* <Select
          className="mb-4"
          options={categoryOptions}
          placeholder="Zones"
          onChange={(value) => handleFilterChange("categories", value)}
          isMulti
          isClearable
          value={filters.categories}
        /> */}
        <Select
          className="mb-4"
          options={allOrderTypes}
          placeholder="Order Type"
          onChange={(value) => handleFilterChange("orderType", value)}
          isMulti
          isClearable
          value={filters.orderType}
        />
        <Select
          className="mb-4"
          options={allBuildingStages}
          placeholder="Order Status"
          onChange={(value) => handleFilterChange("buildingStages", value)}
          isMulti
          isClearable
          value={filters.buildingStages}
        />

        <Select
          options={modelOptions}
          placeholder="Models"
          onChange={(value) => handleFilterChange("models", value)}
          isMulti
          isClearable
          value={filters.models}
        />
      </Modal>
      <div className="mt-4 mb-4">
        <strong>Active filters: </strong>
        {filters.dates.length > 0 && (
          <Tag
            className="filtertag"
            color="blue"
            key={filters.dates.toString()}
            // closable
            onClose={() => clearFilter("dates", filters.dates)}
          >
            {`Date: ${filters.dates[0].format("MM-DD-YYYY")} to ${filters.dates[1].format("MM-DD-YYYY")}`}
          </Tag>
        )}
        {filters.orderDate && (
          <Tag
            className="filtertag"
            color="blue"
            key={filters.orderDate.value}
            onClose={() => clearFilter("orderDate", filters.orderDate)}
          >
            {`Order Date: ${filters.orderDate.label}`}
          </Tag>
        )}

        {filters.orderType.length > 0 &&
          filters.orderType.map((type) => (
            <Tag className="filtertag" color="blue" key={type.value} closable onClose={() => clearFilter("orderType", type)}>
              {`Type: ${type.label}`}
            </Tag>
          ))}
        {filters.buildingStages.length > 0 &&
          filters.buildingStages.map((stage) => (
            <Tag
              className="filtertag"
              color="blue"
              key={stage.value}
              closable
              onClose={() => clearFilter("buildingStages", stage)}
            >
              {`Status: ${stage.label}`}
            </Tag>
          ))}
        {filters.shops.length > 0 &&
          filters.shops.map((shop) => (
            <Tag className="filtertag" color="blue" key={shop.value} closable onClose={() => clearFilter("shops", shop)}>
              {`Shop: ${shop.label}`}
            </Tag>
          ))}
        {filters.models.length > 0 &&
          filters.models.map((model) => (
            <Tag className="filtertag" color="blue" key={model.value} closable onClose={() => clearFilter("models", model)}>
              {`Model: ${model.label}`}
            </Tag>
          ))}
        {filters.categories.length > 0 &&
          filters.categories.map((category) => (
            <Tag
              className="filtertag"
              color="blue"
              key={category.value}
              closable
              onClose={() => clearFilter("categories", category)}
            >
              {`Zone: ${category.label}`}
            </Tag>
          ))}
      </div>
    </div>
  );
}

export default FilterComponent;
