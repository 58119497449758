import React, { useReducer } from "react";
import ComponentContext from "./componentContext";
import componentReducer from "./componentReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_COMPONENT,
  DELETE_COMPONENT,
  CLEAR_COMPONENTS,
  SET_CURRENT_COMPONENT,
  CLEAR_CURRENT_COMPONENT,
  UPDATE_COMPONENT,
  FILTER_COMPONENTS,
  CLEAR_FILTER_COMPONENT,
  COMPONENT_ERROR,
  GET_COMPONENTS,
  SET_LOADING_COMPONENT,
} from "../types";

const ComponentState = (props) => {
  const initialState = {
    components: null,
    currentComponent: null,
    filteredComponents: null,
    loadingComponent: false,
    error: null,
  };

  const [state, dispatch] = useReducer(componentReducer, initialState);

  // get all components
  const getComponents = async (shedcatid, id, shopid) => {
    try {
      const res = await axios.get(`/api/component/${shedcatid}/${id}/${shopid}`);
      dispatch({ type: GET_COMPONENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  // get all components
  const getMetalComponents = async (id, shopid) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      console.log("here");
      const res = await axios.put(`/api/component/metalcomponent/${id}/${shopid}`, config);
      dispatch({ type: GET_COMPONENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  // get all components
  const getAllComponents = async (shedcat) => {
    try {
      const res = await axios.get(`/api/component/${shedcat}`);
      dispatch({ type: GET_COMPONENTS, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  const addComponent = async (component) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/component/", { component }, config);
      dispatch({ type: ADD_COMPONENT, payload: res.data });
      toast.success(`Component is added`);
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  const deleteComponent = async (id) => {
    try {
      const res = await axios.delete(`/api/component/delete/${id}`);
      dispatch({ type: DELETE_COMPONENT, payload: id });
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  // update component
  const updateComponent = async (updatedComponent) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/component/update/${updatedComponent._id}`, updatedComponent, config);
      dispatch({ type: UPDATE_COMPONENT, payload: updatedComponent });
      toast.success(`component is updated`);
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  const updateShopComponent = async (updatedComponent) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/component/shop/update/${updatedComponent._id}`, updatedComponent, config);
      dispatch({ type: UPDATE_COMPONENT, payload: updatedComponent });
      toast.success(`component is updated`);
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  const updateAllComponents = async (components) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/component/import", { components }, config);
      dispatch({ type: UPDATE_COMPONENT, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  const updateSelectedCategoriesComponents = async (shedCategoryId, categoryId, shedCategoryIds) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(
        `/api/component/shedcategorycomponents/${categoryId}/${shedCategoryId}`,
        { shedCategoryIds },
        config
      );
      dispatch({ type: UPDATE_COMPONENT, payload: res.data });
    } catch (err) {
      dispatch({ type: COMPONENT_ERROR });
    }
  };

  const clearComponents = () => {
    dispatch({ type: CLEAR_COMPONENTS });
  };

  const setCurrentComponent = (component) => {
    dispatch({ type: SET_CURRENT_COMPONENT, payload: component });
  };

  const clearCurrentComponent = () => {
    dispatch({ type: CLEAR_CURRENT_COMPONENT });
  };

  const filterComponents = (text) => {
    dispatch({ type: FILTER_COMPONENTS, payload: text });
  };

  const clearFilterComponent = () => {
    dispatch({ type: CLEAR_FILTER_COMPONENT });
  };

  const setLoadingComponent = (bool) => {
    dispatch({ type: SET_LOADING_COMPONENT, payload: bool });
  };

  return (
    <ComponentContext.Provider
      value={{
        components: state.components,
        currentComponent: state.currentComponent,
        filteredComponents: state.filteredComponents,
        loadingComponent: state.loadingComponent,
        error: state.error,
        getComponents,
        addComponent,
        deleteComponent,
        setCurrentComponent,
        clearCurrentComponent,
        updateComponent,
        filterComponents,
        clearFilterComponent,
        clearComponents,
        setLoadingComponent,
        updateAllComponents,
        getAllComponents,
        updateShopComponent,
        getMetalComponents,
        updateSelectedCategoriesComponents,
      }}
    >
      {props.children}
    </ComponentContext.Provider>
  );
};

export default ComponentState;
