import React, { Fragment, useEffect, useState, useContext } from "react";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table, Button, Modal, Form, Input, message } from "antd";

const AllComponentCategories = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;
  const componentCategoryContext = useContext(ComponentCategoryContext);
  const {
    getComponentCategorys,
    componentCategorys,
    currentComponentCategory,
    loadingComponentCategory,
    setCurrentComponentCategory,
    // clearCurrentComponent,
    updateAllComponentCategories,
    updateComponentCategory,
  } = componentCategoryContext;

  const componentContext = useContext(ComponentContext);
  const { getAllComponents, components, updateAllComponents } = componentContext;

  // State to manage modal visibility and selected category
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // State to manage form data
  const [form] = Form.useForm();

  // Function to handle Edit button click
  const handleEditComponentCategory = (componentCategory) => {
    setSelectedCategory(componentCategory);
    form.setFieldsValue({
      name: componentCategory.name,
      priority: componentCategory.priority,
      _id: componentCategory._id,
    });
    setIsModalVisible(true);
  };

  // Function to handle modal form submission
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("values", values);
        updateComponentCategory(values);
        // Assuming you would save the updated componentCategory to the backend here
        message.success(`Component Category "${values.name}" updated successfully!`);
        // Close the modal
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.error("Validation failed:", info);
      });
  };

  // Function to close the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    // clearCurrentComponent();
    // getAllComponents(currentShedCategory._id);
    getComponentCategorys(currentShedCategory._id);
  }, []);

  useEffect(() => {}, [componentCategorys]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // console.log("this is the components", components);

  const exportFile = () => {
    // Group components by category name
    const componentsByCategory = {};

    components &&
      components.forEach((component) => {
        const categoryName = component.category && component.category.name ? component.category.name : "Uncategorized";
        if (!componentsByCategory[categoryName]) {
          componentsByCategory[categoryName] = [];
        }
        componentsByCategory[categoryName].push(component);
      });

    // Create a new workbook
    const wb = utils.book_new();

    // Iterate over each category to create worksheets
    for (const categoryName in componentsByCategory) {
      const itemsForExport = [];

      componentsByCategory[categoryName].forEach((component) => {
        const componentItem = {
          ShedCategory: component.shedCategory,
          Category: categoryName,
          ID: component._id,
          Name: component.name,
        };

        component.standardItems &&
          component.standardItems.forEach((standardItem, index) => {
            componentItem[`Name_${index + 1}`] = standardItem.item.name;
            componentItem[`ID_${index + 1}`] = standardItem.item._id;
            componentItem[`Count_${index + 1}`] = standardItem.quantity;
          });

        itemsForExport.push(componentItem);
      });
      // Create a worksheet for the current category
      const ws = utils.json_to_sheet(itemsForExport);

      // Apply column widths to hide the "ID" column
      const colWidths = [];
      const headers = Object.keys(itemsForExport[0]);
      headers.forEach((header, colIndex) => {
        if (header.toLowerCase().includes("id")) {
          colWidths.push({ hidden: true }); // Auto-hide columns containing "ID"
        } else {
          colWidths.push({ wch: 20 }); // Default column width
        }
      });
      ws["!cols"] = colWidths;

      // Sanitize the sheet name to ensure it's valid
      const sheetName = sanitizeSheetName(categoryName);

      // Append the worksheet to the workbook
      utils.book_append_sheet(wb, ws, sheetName);
    }
    // Write the workbook to a file
    writeFileXLSX(wb, `${currentShedCategory.name}_Components_BOM.xlsx`);
  };

  function sanitizeSheetName(name) {
    return name.replace(/[\[\]:\*\/\\\?\']+/g, "").substring(0, 31);
  }

  const importFile = () => {
    updateAllComponents(newFile);
  };

  const uploadFile = (e) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });

      // Initialize an object to store data from all sheets
      const allSheetsData = {};

      // Iterate through all sheet names in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];

        // Convert the sheet to JSON
        const sheetData = utils.sheet_to_json(worksheet);

        // Store the data under the corresponding sheet name
        allSheetsData[sheetName] = sheetData;
      });

      // Set the parsed data (all sheets) to a state variable or process it as needed
      console.log(JSON.stringify(allSheetsData, null, 2));
      setNewFile(allSheetsData.Data);
    };

    // Read the file as an ArrayBuffer
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const sortedCategories = componentCategorys
    ? componentCategorys.sort((a, b) => {
        // If both have a priority, compare them normally
        if (a.priority !== undefined && b.priority !== undefined) {
          return a.priority - b.priority;
        }
        // If `a` has no priority, it should be sorted after `b`
        if (a.priority === undefined && b.priority !== undefined) {
          return 1;
        }
        // If `b` has no priority, it should be sorted after `a`
        if (b.priority === undefined && a.priority !== undefined) {
          return -1;
        }
        // If neither has a priority, leave them as equal
        return 0;
      })
    : [];

  const columns = [
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewComponent(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Edit",
      width: "10%",
      render: (text, record) => (
        <span>
          <button
            className="btn btn-xs btn-outline-danger ms-2"
            type="button"
            onClick={() => handleEditComponentCategory(record)}
          >
            Edit
          </button>
        </span>
      ),
    },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddComponent = () => {
    navigate("/company/component/category/form");
  };

  const handleViewComponent = (componentCategory) => {
    setCurrentComponentCategory(componentCategory);
    navigate("/company/component/all");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddComponent()}
                >
                  Add Component Category
                </button>
                <button className="mb-2 btn btn-outline-danger float-end" onClick={() => handleBack()}>
                  Back
                </button>
                {/* {componentCategorys && (
                  <button className="mb-2 me-2 btn btn-outline-info float-end" onClick={() => exportFile()}>
                    DownLoad Components BOM
                  </button>
                )} */}
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              loading={componentCategorys ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              dataSource={sortedCategories && sortedCategories}
              // dataSource={testModels}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
            {/* Ant Design Modal for editing the component category */}
            <Modal
              title="Edit Component Category"
              visible={isModalVisible}
              onOk={handleFormSubmit}
              onCancel={handleCancel}
              okText="Save"
              cancelText="Cancel"
            >
              <Form form={form} layout="vertical" name="editComponentCategoryForm">
                <Form.Item
                  name="name"
                  label="Component Category Name"
                  rules={[{ required: true, message: "Please input the component category name!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="priority"
                  label="Priority"
                  rules={[{ required: true, message: "Please input the priority level!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="_id"
                  label="ID"
                  hidden
                  // rules={[{ required: true, message: "Please input the priority level!" }]}
                >
                  <Input />
                </Form.Item>
              </Form>
            </Modal>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
          </div>
          <div className="col-md-4">
            <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
              Upload Updated BOM
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllComponentCategories;
