import React, { useState, useEffect, useContext } from "react";
import ShedContext from "../../../../context/shed/shedContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import Select from "react-select";

import { read, utils, writeFileXLSX } from "xlsx";

const RetailPriceChange = () => {
  const shedContext = useContext(ShedContext);
  const { getShedsForPriceChange, sheds, clearSheds, updateRetailPriceShedImport } = shedContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  const [newFile, setNewFile] = useState("");

  // useEffect(() => {
  //   getShedCategorys();
  //   // clearItems when unmounting
  //   return () => {
  //     clearSheds();
  //   };
  // }, []);

  const importFile = () => {
    updateRetailPriceShedImport(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      // Initialize an empty array to hold all items
      let allItems = [];
      // Iterate over all sheets in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        // Append items from this sheet to the allItems array
        allItems = [...allItems, ...json];
      });
      setNewFile(allItems);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  useEffect(() => {
    clearSheds();
  }, []);

  let shedsForExportByModel = {};

  sheds &&
    sheds.forEach((shed) => {
      // Group sheds by model
      if (!shedsForExportByModel[shed.model.name]) {
        shedsForExportByModel[shed.model.name] = [];
      }
      let newShed = {};
      newShed.Id = shed._id;
      newShed.Model = shed.model.name;
      newShed.Width = shed.width;
      newShed.Length = shed.length;
      newShed.RetailPrice = shed.retailPrice;

      shedsForExportByModel[shed.model.name].push(newShed);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each model
    for (let model in shedsForExportByModel) {
      const ws = utils.json_to_sheet(shedsForExportByModel[model]);
      const sanitizedModel = sanitizeSheetName(model);
      utils.book_append_sheet(wb, ws, sanitizedModel);
    }
    writeFileXLSX(wb, `Sheds.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }

  // map out shed categorys for react-select
  const allShedCategorys =
    shedCategorys &&
    shedCategorys.map((shedCategory) => ({
      value: shedCategory,
      label: shedCategory.name,
    }));

  const handleCategoryChange = (shedCategory) => {
    getShedsForPriceChange(shedCategory.value._id);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {/* <div className="col-md-1"></div> */}
        <div className="col-md-10">
          {/* <div className="col-md-2">
            <div className="form-group mt-4">
              <Select
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select Category"
                name="shedCategory"
                options={allShedCategorys}
                onChange={handleCategoryChange}
              />
            </div>
          </div> */}

          <div className="row mt-4">
            <p>Download the Excel file (EXPORT MODELS) from Shed-Suite and upload it here.</p>
            {/* <div className="col-md-2">
              {sheds && sheds.length > 0 && (
                <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
                  Export Excel
                </button>
              )}
            </div> */}
            <div className="col-md-3">
              <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
            </div>
            <div className="col-md-2">
              <button className="mb-2 me-2 btn btn-info" onClick={() => importFile()}>
                Import Excel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetailPriceChange;
