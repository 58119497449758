import React, { useContext, useState, Fragment } from "react";
import ComponentContext from "../../../../../../context/component/componentContext";
import { useFieldArray, Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { Modal, Button, Row, Col, Card, Typography, Input } from "antd";
import ComponentForm from "../../components/component/ComponentForm";

const { Title } = Typography;

const Components = ({ component, componentIndex, control, remove, setValue, getValues, components, register }) => {
  const setComponentCount = (count) => {
    setValue(`components[${componentIndex}].quantity`, count);
  };

  const componentContext = useContext(ComponentContext);
  const { setCurrentComponent } = componentContext;

  const onRemovecomponent = () => {};

  const theComponentOptions =
    components &&
    components.map((component) => {
      return { label: `${component.name}`, value: component };
    });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const viewComponent = () => {
    const newComponent = component.component;
    setCurrentComponent(newComponent);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Fragment>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ top: "12.5%" }}
        width="75vw"
        bodyStyle={{ height: "85vh", overflow: "auto", maxWidth: "100%" }}
        footer={null}
      >
        <ComponentForm setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
      </Modal>
      <Card
        style={{
          marginBottom: "8px",
          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
          border: "1px solid #e8e8e8",
          borderRadius: "8px",
        }}
      >
        <Row gutter={8} align="middle">
          <Col span={8}>
            <Controller
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  value={(field.value && { label: field.value.name, value: field.value }) || null}
                  isClearable={true}
                  options={theComponentOptions}
                  menuPortalTarget={document.body}
                  menuPosition={"fixed"}
                  onChange={(e) => {
                    field.onChange(e.value);
                    setValue(`components[${componentIndex}].component`, e.value);
                    setValue(`components[${componentIndex}].quantity`, 0);
                  }}
                />
              )}
              key={component.id}
              name={`components[${componentIndex}].component`}
              control={control}
            />
          </Col>
          <Col span={4}>
            <Controller
              render={({ field, name }) => (
                <Input
                  type="number"
                  name={name}
                  {...field}
                  onChange={(e) => {
                    const count = e.target.value;
                    setComponentCount(count);
                  }}
                />
              )}
              key={component.id}
              control={control}
              name={`components[${componentIndex}].quantity`}
            />
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button
              type="danger"
              onClick={() => {
                remove(componentIndex);
                onRemovecomponent();
              }}
            >
              Delete
            </Button>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
            <Button type="primary" onClick={viewComponent}>
              View
            </Button>
          </Col>
        </Row>
      </Card>
    </Fragment>
  );
};

export default function Fields({ control, register, setValue, getValues, components }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: "components",
  });

  return (
    <div className="container-fluid">
      <Row justify="center">
        <Col span={24}>
          <Title level={4} className="text-center">
            Components
          </Title>
        </Col>
      </Row>
      <Row gutter={8} align="middle" style={{ marginBottom: "8px" }}>
        <Col span={8} className="text-center">
          <strong>Name</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Count</strong>
        </Col>
        <Col span={4} className="text-center">
          <strong>Actions</strong>
        </Col>
      </Row>
      {fields.map((component, componentIndex) => (
        <Components
          key={component.id}
          component={component}
          componentIndex={componentIndex}
          remove={remove}
          register={register}
          control={control}
          setValue={setValue}
          getValues={getValues}
          components={components}
        />
      ))}
      <Row justify="center">
        <Col>
          <Button
            type="dashed"
            onClick={(e) => {
              e.preventDefault();
              append({ value: "0" });
            }}
          >
            Add Component
          </Button>
        </Col>
      </Row>
    </div>
  );
}
