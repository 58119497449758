import React, { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import HaulBaseContext from "./haulBaseContext";
import haulBaseReducer from "./haulBaseReducer";
import axios from "axios";

import { toast } from "react-toastify";

import {
  ADD_HAULBASE,
  DELETE_HAULBASE,
  CLEAR_HAULBASES,
  SET_CURRENT_HAULBASE,
  CLEAR_CURRENT_HAULBASE,
  UPDATE_HAULBASE,
  HAULBASE_ERROR,
  GET_HAULBASES,
} from "../types";

const HaulBaseState = (props) => {
  const navigate = useNavigate();
  const initialState = {
    haulBases: null,
    currentHaulBase: null,
    error: null,
  };

  const [state, dispatch] = useReducer(haulBaseReducer, initialState);

  const getHaulBasesByModel = async (model, cat) => {
    try {
      const res = await axios.get(`/api/haulbase/model/${model._id}/${cat}`, {
        // params: {
        //   id: model._id,
        // },
      });
      dispatch({ type: GET_HAULBASES, payload: res.data });
    } catch (err) {
      dispatch({ type: HAULBASE_ERROR });
      toast.error(`Haul Base ${err}`);
    }
  };

  const getHaulBase = async (cat) => {
    try {
      const res = await axios.get(`/api/haulbase/${cat}`);
      dispatch({ type: GET_HAULBASES, payload: res.data });
    } catch (err) {
      dispatch({ type: HAULBASE_ERROR });
      toast.error(`Shed ${err}`);
    }
  };

  // const getAllShedsLabor = async () => {
  //   try {
  //     const res = await axios.get(`/api/haulbase`);
  //     dispatch({ type: GET_HAULBASES, payload: res.data });
  //   } catch (err) {
  //     dispatch({ type: HAULBASE_ERROR });
  //     toast.error(`Shed ${err}`);
  //   }
  // };

  // add
  const addHaulBase = async (haulBase) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/haulbase", haulBase, config);
      dispatch({ type: ADD_HAULBASE, payload: res.data });
    } catch (err) {
      dispatch({ type: HAULBASE_ERROR });
    }
  };

  const importHaulBases = async (haulBase) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post("/api/haulbase/import", { haulBase }, config);
      dispatch({ type: ADD_HAULBASE, payload: res.data });
    } catch (err) {
      dispatch({ type: HAULBASE_ERROR });
    }
  };

  // delete
  const deleteHaulBase = async (id) => {
    try {
      const res = await axios.delete(`/api/haulbase/delete/${id}`);
      dispatch({ type: DELETE_HAULBASE, payload: id });
      toast.success(`HaulBase is deleted`);
    } catch (err) {
      dispatch({ type: HAULBASE_ERROR });
    }
  };

  // update
  const updateHaulBase = async (labor) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.put(`/api/haulbase/update/${labor._id}`, labor, config);
      // const res = await axios.put(`/api/shed/change/allsheds`, config);
      // const res = await axios.put(`/api/shed/copy`, shed, config)
      dispatch({ type: UPDATE_HAULBASE, payload: res.data });
      navigate(-1);
      toast.success(`Shed Labor is updated`);
    } catch (err) {
      dispatch({ type: HAULBASE_ERROR });
      toast.error(`Shed Labor ${err}`);
    }
  };

  // clear
  const clearShedsLabor = () => {
    dispatch({ type: CLEAR_HAULBASES });
  };

  // set current
  const setCurrentHaulBase = (labor) => {
    dispatch({ type: SET_CURRENT_HAULBASE, payload: labor });
  };

  // clear current
  const clearCurrentShopHaulBase = () => {
    dispatch({ type: CLEAR_CURRENT_HAULBASE });
  };

  return (
    <HaulBaseContext.Provider
      value={{
        haulBases: state.haulBases,
        currentHaulBase: state.currentHaulBase,
        error: state.error,
        getHaulBasesByModel,
        getHaulBase,
        addHaulBase,
        deleteHaulBase,
        setCurrentHaulBase,
        clearCurrentShopHaulBase,
        updateHaulBase,
        clearShedsLabor,
        importHaulBases,
        // getAllShedsLabor,
        // updateAllSheds,
      }}
    >
      {props.children}
    </HaulBaseContext.Provider>
  );
};

export default HaulBaseState;
