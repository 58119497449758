import React, { useState, useContext, useEffect, Fragment, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ShopContext from "../../../../context/shop/shopContext";
import AuthContext from "../../../../context/auth/authContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import AllUsers from "./users/AllUsers";
import AddUser from "./users/AddUser";
import { Modal, Form, Input, Button, Card, Space, Select, Typography, Layout, Divider } from "antd";
import BaseStyles from "./BaseStyles";

const { Title } = Typography;
const { Content } = Layout;
const { Option } = Select;

const SettingsForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [categories, setCategories] = useState([]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSelectChange = (selectedOption) => {
    form.setFieldsValue({ defaultCategory: selectedOption });
  };

  const shopContext = useContext(ShopContext);
  const { addShop, currentShop, updateShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { clearCurrentUser } = authContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { getShedCategorys, shedCategorys } = shedCategoryContext;

  useEffect(() => {
    getShedCategorys();
    if (currentShop) {
      const defaultCategory = {
        category: currentShop.defaultCategory
          ? {
              label: currentShop.defaultCategory.name,
              value: currentShop.defaultCategory._id,
            }
          : null,
        standardFourteenSixteenNBPercent: currentShop.standardFourteenSixteenNBPercent,
        standardFourteenSixteenINVPercent: currentShop.standardFourteenSixteenINVPercent,
        standardEightTenTwelveNBPercent: currentShop.standardEightTenTwelveNBPercent,
        standardEightTenTwelveINVPercent: currentShop.standardEightTenTwelveINVPercent,
        fourteenSixteenNBPercent: currentShop.fourteenSixteenNBPercent,
        fourteenSixteenINVPercent: currentShop.fourteenSixteenINVPercent,
        eightTenTwelveNBPercent: currentShop.eightTenTwelveNBPercent,
        eightTenTwelveINVPercent: currentShop.eightTenTwelveINVPercent,
        defaultEmail: currentShop.defaultEmail,
      };

      form.setFieldsValue({
        name: currentShop.name,
        defaultEmail: currentShop.defaultEmail,
        streetAddress: currentShop.streetAddress,
        city: currentShop.city,
        state: currentShop.state,
        zipCode: currentShop.zipCode,
        overhead: currentShop.overhead,
        fourteenSixteenNBPercent: currentShop.fourteenSixteenNBPercent,
        fourteenSixteenINVPercent: currentShop.fourteenSixteenINVPercent,
        eightTenTwelveNBPercent: currentShop.eightTenTwelveNBPercent,
        eightTenTwelveINVPercent: currentShop.eightTenTwelveINVPercent,
        shopAddOnPercent: currentShop.shopAddOnPercent,
        defaultCategory: defaultCategory.category,
      });

      // Transform currentShop.categories to ensure each category has a label and a value
      setCategories((prevCategories) => {
        if (prevCategories.length > 0) {
          return prevCategories;
        }

        const formattedCategories = currentShop.categories
          ? currentShop.categories.map((cat) => ({
              category: {
                label: cat.category.name,
                value: cat.category._id,
              },
              standardFourteenSixteenNBPercent: cat.standardFourteenSixteenNBPercent,
              standardFourteenSixteenINVPercent: cat.standardFourteenSixteenINVPercent,
              standardEightTenTwelveNBPercent: cat.standardEightTenTwelveNBPercent,
              standardEightTenTwelveINVPercent: cat.standardEightTenTwelveINVPercent,
              fourteenSixteenNBPercent: cat.fourteenSixteenNBPercent,
              fourteenSixteenINVPercent: cat.fourteenSixteenINVPercent,
              eightTenTwelveNBPercent: cat.eightTenTwelveNBPercent,
              eightTenTwelveINVPercent: cat.eightTenTwelveINVPercent,
            }))
          : [];

        return formattedCategories;
      });
    }
  }, [currentShop]);

  const handleSubmit = (values) => {
    const shopData = {
      ...values,
      categories: categories,
    };
    if (currentShop) {
      updateShop(currentShop._id, shopData);
      navigate(-1);
    } else {
      addShop(shopData);
      navigate(-1);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleAddUser = () => {
    clearCurrentUser();
    showModal();
  };

  const categoryOptions =
    shedCategorys &&
    shedCategorys.map((category) => ({
      value: category._id,
      label: category.name,
    }));

  const cardStyle = {
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  };

  const buttonStyle = {
    marginRight: "10px",
  };

  const handleAddCategory = useCallback(() => {
    console.log("handleAddCategory called");
    const newCategory = {
      category: null,
      standardFourteenSixteenNBPercent: "",
      standardFourteenSixteenINVPercent: "",
      standardEightTenTwelveNBPercent: "",
      standardEightTenTwelveINVPercent: "",
      fourteenSixteenNBPercent: "",
      fourteenSixteenINVPercent: "",
      eightTenTwelveNBPercent: "",
      eightTenTwelveINVPercent: "",
    };

    setCategories((prevCategories) => [...prevCategories, newCategory]);
  }, [form]);

  const handleCategoryChange = useCallback((index, field, value) => {
    setCategories((prevCategories) => {
      const newCategories = [...prevCategories];
      newCategories[index] = {
        ...newCategories[index],
        [field]: value,
      };
      return newCategories;
    });
  }, []);

  const handleRemoveCategory = useCallback((index) => {
    setCategories((prevCategories) => prevCategories.filter((_, i) => i !== index));
  }, []);

  const inputStyle = {
    width: "100%",
    marginBottom: "8px",
  };

  const labelStyle = {
    fontWeight: "bold",
    marginBottom: "4px",
    display: "block",
  };

  const defaultCategoryCardStyle = {
    backgroundColor: "#a9a9a9", // Medium gray background
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    marginBottom: "24px",
  };

  const categoryColors = ["#4682B4", "#6CA6D9"]; // Steel Blue and a lighter shade of Steel Blue

  const percentageInputProps = {
    type: "number",
    step: "0.01",
    min: "0",
    max: "100",
    style: inputStyle,
  };

  return (
    <Layout>
      <Content style={{ padding: "24px", backgroundColor: "#f0f2f5" }}>
        <Card style={cardStyle}>
          <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Title level={2}>Shop Form</Title>
              <Button type="primary" danger onClick={() => navigate(-1)}>
                Exit
              </Button>
            </div>

            <Form layout="vertical" onFinish={handleSubmit} form={form}>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                <Form.Item label="Shop Name" name="name" style={{ flex: 1, minWidth: "150px" }}>
                  <Input />
                </Form.Item>
                <Form.Item label="Default Email" name="defaultEmail" style={{ flex: 1, minWidth: "150px" }}>
                  <Input />
                </Form.Item>
                <Form.Item label="Overhead %" name="overhead" style={{ flex: 1, minWidth: "50px" }}>
                  <Input />
                </Form.Item>
              </div>

              <Divider orientation="left">Address</Divider>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
                <Form.Item label="Street" name="streetAddress" style={{ flex: 2, minWidth: "200px" }}>
                  <Input />
                </Form.Item>
                <Form.Item label="City" name="city" style={{ flex: 1, minWidth: "200px" }}>
                  <Input />
                </Form.Item>
                <Form.Item label="State" name="state" style={{ flex: 1, minWidth: "100px" }}>
                  <Input />
                </Form.Item>
                <Form.Item label="Zip Code" name="zipCode" style={{ flex: 1, minWidth: "100px" }}>
                  <Input />
                </Form.Item>
              </div>

              <Card title="Default Category" style={defaultCategoryCardStyle}>
                <Form.Item label="Default Category" name="defaultCategory" style={{ width: "100%", maxWidth: "400px" }}>
                  <Select disabled={true} options={categoryOptions} placeholder="Select Default Category" />
                </Form.Item>

                {/* <Card title="Default Standard Percentages" size="small" style={{ marginBottom: "16px" }}>
                  <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "16px" }}>
                    <Form.Item label="14-16 NB % (Standard)" name="standardFourteenSixteenNBPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                    <Form.Item label="14-16 INV % (Standard)" name="standardFourteenSixteenINVPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                    <Form.Item label="8-12 NB % (Standard)" name="standardEightTenTwelveNBPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                    <Form.Item label="8-12 INV % (Standard)" name="standardEightTenTwelveINVPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                  </div>
                </Card> */}

                <Card title="Default Shop Percentages" size="small" style={{ marginBottom: "16px" }}>
                  <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "16px" }}>
                    <Form.Item label="14-16 NB % (Shop)" name="fourteenSixteenNBPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                    <Form.Item label="14-16 INV % (Shop)" name="fourteenSixteenINVPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                    <Form.Item label="8-12 NB % (Shop)" name="eightTenTwelveNBPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                    <Form.Item label="8-12 INV % (Shop)" name="eightTenTwelveINVPercent">
                      <Input {...percentageInputProps} />
                    </Form.Item>
                  </div>
                </Card>
              </Card>

              <Divider orientation="left">Additional Categories</Divider>
              {categories.map((category, index) => (
                <Card
                  key={`category-${index}`}
                  style={{
                    marginBottom: 24,
                    backgroundColor: categoryColors[index % categoryColors.length],
                  }}
                >
                  <Space direction="vertical" style={{ width: "100%" }}>
                    <Select
                      style={{ width: "100%", marginBottom: "16px" }}
                      disabled={true}
                      value={category.category}
                      onChange={(value) =>
                        handleCategoryChange(index, "category", {
                          value,
                          label: categoryOptions.find((opt) => opt.value === value)?.label,
                        })
                      }
                      options={categoryOptions}
                      placeholder="Select Category"
                    />

                    {/* <Card title="Standard Percentages" size="small" style={{ marginBottom: "16px" }}>
                      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "16px" }}>
                        <div>
                          <label style={labelStyle}>14-16 NB % (Standard)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.standardFourteenSixteenNBPercent}
                            onChange={(e) => handleCategoryChange(index, "standardFourteenSixteenNBPercent", e.target.value)}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>14-16 INV % (Standard)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.standardFourteenSixteenINVPercent}
                            onChange={(e) => handleCategoryChange(index, "standardFourteenSixteenINVPercent", e.target.value)}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>8-12 NB % (Standard)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.standardEightTenTwelveNBPercent}
                            onChange={(e) => handleCategoryChange(index, "standardEightTenTwelveNBPercent", e.target.value)}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>8-12 INV % (Standard)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.standardEightTenTwelveINVPercent}
                            onChange={(e) => handleCategoryChange(index, "standardEightTenTwelveINVPercent", e.target.value)}
                          />
                        </div>
                      </div>
                    </Card> */}

                    <Card title="Shop Percentages" size="small">
                      <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))", gap: "16px" }}>
                        <div>
                          <label style={labelStyle}>14-16 NB % (Shop)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.fourteenSixteenNBPercent}
                            onChange={(e) => handleCategoryChange(index, "fourteenSixteenNBPercent", e.target.value)}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>14-16 INV % (Shop)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.fourteenSixteenINVPercent}
                            onChange={(e) => handleCategoryChange(index, "fourteenSixteenINVPercent", e.target.value)}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>8-12 NB % (Shop)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.eightTenTwelveNBPercent}
                            onChange={(e) => handleCategoryChange(index, "eightTenTwelveNBPercent", e.target.value)}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>8-12 INV % (Shop)</label>
                          <Input
                            {...percentageInputProps}
                            value={category.eightTenTwelveINVPercent}
                            onChange={(e) => handleCategoryChange(index, "eightTenTwelveINVPercent", e.target.value)}
                          />
                        </div>
                      </div>
                    </Card>
                  </Space>
                  {/* <Button type="link" danger onClick={() => handleRemoveCategory(index)} style={{ marginTop: 16 }}>
                    Remove Category
                  </Button> */}
                </Card>
              ))}
              {/* <Button
                type="button"
                onClick={handleAddCategory}
                style={{
                  marginTop: 16,
                  width: "auto",
                  alignSelf: "flex-start",
                }}
              >
                Add Category
              </Button> */}

              <Form.Item style={{ marginTop: 24 }}>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
              </Form.Item>
            </Form>
          </Space>
        </Card>

        {currentShop && (
          <Card style={{ ...cardStyle, marginTop: 24 }}>
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Title level={3}>Shop Users</Title>
              <Button type="primary" onClick={handleAddUser}>
                Add User
              </Button>
              <AllUsers />
            </Space>
          </Card>
        )}

        <Modal
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={null}
          bodyStyle={{
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          <AddUser />
        </Modal>
        <BaseStyles />
      </Content>
    </Layout>
  );
};

export default SettingsForm;
//                       </Card>
//                     </Space>
//                     <Button type="link" danger onClick={() => handleRemoveCategory(index)} style={{ marginTop: 16 }}>
//                       Remove Category
//                     </Button>
//                   </Card>
//                 ))}
//                 <Button
//                   type="button"
//                   onClick={handleAddCategory}
//                   style={{
//                     marginTop: 16,
//                     width: "auto",
//                     alignSelf: "flex-start",
//                   }}
//                 >
//                   Add Category
//                 </Button>

//                 <Form.Item style={{ textAlign: "right", marginTop: "1rem" }}>
//                   <button className="settingsSubmitBtn" type="submit" style={{ width: "auto", display: "inline-block" }}>
//                     Submit
//                   </button>
//                 </Form.Item>
//               </Card>
//             </Form>
//           </div>
//           <div className="col-md-6">
//             {currentShop && (
//               <Fragment>
//                 <Button type="primary" onClick={handleAddUser}>
//                   Add User
//                 </Button>
//                 <Modal
//                   // title="Add User"
//                   visible={isModalVisible}
//                   onOk={handleOk}
//                   onCancel={handleCancel}
//                   footer={null} // Remove the default footer buttons
//                   bodyStyle={{
//                     maxHeight: "50vh", // Adjust the height based on your needs
//                     overflowY: "auto",
//                   }}
//                 >
//                   <AddUser />
//                 </Modal>

//                 <AllUsers />
//               </Fragment>
//             )}
//           </div>
//         </div>
//       </div>

//       <BaseStyles />
//     </Fragment>
//   );
// };

// export default SettingsForm;
