import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../../context/item/itemContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./ComponentFormItemArr";
import { Checkbox, Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const defaultValues = {
  name: "",
  category: null,
  standardItems: [
    {
      item: {},
    },
  ],
};

const ComponentForm = ({ setIsModalVisible, isModalVisible }) => {
  const navigate = useNavigate();
  const itemContext = useContext(ItemContext);
  const { getItems, allItems, clearItems } = itemContext;

  const componentContext = useContext(ComponentContext);
  const { addComponent, currentComponent, clearCurrentComponent, updateComponent } = componentContext;

  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  console.log("This is allItems", allItems);

  useEffect(() => {
    currentComponent && reset(currentComponent);
    currentComponentCategory && setValue("category", currentComponentCategory._id);
    currentShedCategory && setValue("shedCategory", currentShedCategory._id);
    getItems();
    return () => {
      clearItems();
    };
  }, []);

  const onSubmit = (data) => {
    if (!currentComponent) {
      addComponent(data);
      navigate(-1);
    } else {
      updateComponent(data);
      navigate(-1);
      clearCurrentComponent();
    }
  };

  const setExit = (e) => {
    e.preventDefault();
    navigate(-1);
    clearCurrentComponent();
  };

  const handleCopy = (e) => {
    e.preventDefault();
    const data = getValues();
    delete data._id;
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    addComponent(data);
  };

  return (
    <div className="container-fluid pb-4">
      <Row justify="center">
        <Col span={24}>
          <Title level={3} className="text-center">
            {currentComponent ? "Update Component" : "Add Component"}
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={20}>
          <Card style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Card
                style={{
                  marginBottom: "16px",
                  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                }}
              >
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Name" required>
                      <Controller name="name" control={control} render={({ field }) => <Input {...field} />} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      <Controller
                        control={control}
                        name="metalPackage"
                        render={({ field: { onChange, onBlur, value, name, ref } }) => (
                          <Checkbox onBlur={onBlur} onChange={onChange} checked={value} inputRef={ref}>
                            Metal Package
                          </Checkbox>
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <ItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  allItems,
                }}
              />
              <Row justify="end">
                <Col>
                  <Button type="primary" htmlType="submit" disabled={user.viewOnly}>
                    Save
                  </Button>
                  {/* <Button type="default" className="ms-2" onClick={(e) => handleCopy(e)}>
                    Copy
                  </Button> */}
                  {!isModalVisible && (
                    <Button type="danger" className="ms-2" onClick={(e) => setExit(e)}>
                      Back
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ComponentForm;
