import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllComponentShedCategories = () => {
  const navigate = useNavigate();
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { getShedCategorys, shedCategorys, setCurrentShedCategory } = shedCategoryContext;

  useEffect(() => {
    getShedCategorys();
  }, []);

  useEffect(() => {}, [shedCategorys]);

  const columns = [
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewComponentsShedCategory(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    // {
    //   title: "Edit",
    //   width: "10%",
    //   render: (text, record) => (
    //     <span>
    //       <button
    //         className="btn btn-xs btn-outline-primary"
    //         type="button"
    //         onClick={() => handleViewComponentsShedCategory(record)}
    //       >
    //         View
    //       </button>
    //       {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditShedCategory(record)}>
    //         Edit
    //       </button> */}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewComponentsShedCategory = (shedCategory) => {
    setCurrentShedCategory(shedCategory);
    navigate("/company/component/category/shops");
  };

  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };

  return (
    <Table
      rowClassName={() => "hover-row"}
      // loading={!loadingProduct && productResults ? false : true}
      pagination={{ pageSize: 60 }}
      columns={columns}
      // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
      dataSource={shedCategorys}
      rowKey="_id"
      onChange={onChange}
      scroll={{ x: 1300, y: 2500 }}
    />
  );
};

export default AllComponentShedCategories;
