import React, { useEffect, useContext, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { Form, Input, Button, Modal, Row, Col, Typography } from "antd";
import CompanyContext from "../../../../context/company/companyContext";
import AuthContext from "../../../../context/auth/authContext";
import BaseStyles from "./BaseStyles";
import AddUser from "./users/AddUser";
import AllUsers from "./users/AllUsers";
import AllRoofColors from "./roofColors/AllRoofColors";
import AddRoofColor from "./roofColors/AddRoofColor";
import AllSidingColors from "./sidingColors/AllSidingColors";
import AddSidingColor from "./sidingColors/AddSidingColor";
import AllTrimColors from "./trimColors/AllTrimColors";
import AddTrimColor from "./trimColors/AddTrimColor";

const { Title } = Typography;

const SettingsForm = () => {
  const navigate = useNavigate();
  const companyContext = useContext(CompanyContext);
  const { company, updateCompany } = companyContext;

  const authContext = useContext(AuthContext);
  const { clearCurrentUser, user } = authContext;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRoofModalVisible, setIsRoofModalVisible] = useState(false);
  const [isSidingModalVisible, setIsSidingModalVisible] = useState(false);
  const [isTrimModalVisible, setIsTrimModalVisible] = useState(false);

  const [selectedRoofColor, setSelectedRoofColor] = useState();
  const [selectedSidingColor, setSelectedSidingColor] = useState();
  const [selectedTrimColor, setSelectedTrimColor] = useState();

  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);

  const showRoofModal = () => setIsRoofModalVisible(true);
  const handleRoofOk = () => setIsRoofModalVisible(false);
  const handleRoofCancel = () => setIsRoofModalVisible(false);

  const showSidingModal = () => setIsSidingModalVisible(true);
  const handleSidingOk = () => setIsSidingModalVisible(false);
  const handleSidingCancel = () => setIsSidingModalVisible(false);

  const showTrimModal = () => setIsTrimModalVisible(true);
  const handleTrimOk = () => setIsTrimModalVisible(false);
  const handleTrimCancel = () => setIsTrimModalVisible(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      shopsRetailPercent: "",
      overheadPercent: "",
    },
  });

  const onSubmit = (data) => {
    updateCompany(company._id, data);
  };

  useEffect(() => {
    company && reset(company);
  }, [company]);

  const handleAddUser = () => {
    clearCurrentUser();
    showModal();
  };

  const handleAddRoofColor = () => showRoofModal();
  const handleAddSidingColor = () => showSidingModal();
  const handleAddTrimColor = () => showTrimModal();
  const handlePriceChange = () => navigate("/company/price/change");

  return (
    <Fragment>
      <div className="container">
        <Row justify="center" className="mb-4">
          <Col span={24}>
            <Title level={2} className="settingsTitle text-center">
              Settings
            </Title>
            <Button type="primary" className="float-end" disabled={user.viewOnly} onClick={handlePriceChange}>
              Retail Price Change
            </Button>
          </Col>
        </Row>

        <Form layout="vertical" onFinish={handleSubmit(onSubmit)} className="settingsForm">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Overhead Percent"
                validateStatus={errors.overheadPercentCompany ? "error" : ""}
                help={errors.overheadPercentCompany && "This field is required"}
              >
                <Controller
                  name="overheadPercentCompany"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="Standard Overhead Percent For Shop"
                validateStatus={errors.overheadPercentShop ? "error" : ""}
                help={errors.overheadPercentShop && "This field is required"}
              >
                <Controller
                  name="overheadPercentShop"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="Dealer Commission"
                validateStatus={errors.dealerCommissionPercent ? "error" : ""}
                help={errors.dealerCommissionPercent && "This field is required"}
              >
                <Controller
                  name="dealerCommissionPercent"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="Sales Rep Commission"
                validateStatus={errors.salesRepCommissionPercent ? "error" : ""}
                help={errors.salesRepCommissionPercent && "This field is required"}
              >
                <Controller
                  name="salesRepCommissionPercent"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Lot to Customer %"
                validateStatus={errors.lotToCustomerPercent ? "error" : ""}
                help={errors.lotToCustomerPercent && "This field is required"}
              >
                <Controller
                  name="lotToCustomerPercent"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="Shop to Lot %"
                validateStatus={errors.shopToLotPercent ? "error" : ""}
                help={errors.shopToLotPercent && "This field is required"}
              >
                <Controller
                  name="shopToLotPercent"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="14' Escort"
                validateStatus={errors.fourteenWideEscort ? "error" : ""}
                help={errors.fourteenWideEscort && "This field is required"}
              >
                <Controller
                  name="fourteenWideEscort"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="16' Escort"
                validateStatus={errors.sixteenWideEscort ? "error" : ""}
                help={errors.sixteenWideEscort && "This field is required"}
              >
                <Controller
                  name="sixteenWideEscort"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item
                label="Fuel Surcharge"
                validateStatus={errors.fuelSurcharge ? "error" : ""}
                help={errors.fuelSurcharge && "This field is required"}
              >
                <Controller
                  name="fuelSurcharge"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Input {...field} type="number" step="0.01" />}
                />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" disabled={user.viewOnly}>
                  Update Company
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div className="container">
          <BaseStyles />
        </div>
        <div className="settingsForm">
          {company && (
            <Fragment>
              <Modal
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
                bodyStyle={{ maxHeight: "50vh", overflowY: "auto" }}
              >
                <AddUser />
              </Modal>

              <Modal
                visible={isRoofModalVisible}
                onOk={handleRoofOk}
                onCancel={handleRoofCancel}
                footer={null}
                bodyStyle={{ maxHeight: "50vh", overflowY: "auto" }}
              >
                <AddRoofColor selectedRoofColor={selectedRoofColor} setSelectedRoofColor={setSelectedRoofColor} />
              </Modal>

              <Modal
                visible={isSidingModalVisible}
                onOk={handleSidingOk}
                onCancel={handleSidingCancel}
                footer={null}
                bodyStyle={{ maxHeight: "50vh", overflowY: "auto" }}
              >
                <AddSidingColor selectedSidingColor={selectedSidingColor} setSelectedSidingColor={setSelectedSidingColor} />
              </Modal>

              <Modal
                visible={isTrimModalVisible}
                onOk={handleTrimOk}
                onCancel={handleTrimCancel}
                footer={null}
                bodyStyle={{ maxHeight: "50vh", overflowY: "auto" }}
              >
                <AddTrimColor selectedTrimColor={selectedTrimColor} setSelectedTrimColor={setSelectedTrimColor} />
              </Modal>

              <AllUsers />
              {user && user.superAdmin ? (
                <Button type="primary" onClick={handleAddUser} disabled={user.viewOnly}>
                  Add User
                </Button>
              ) : null}

              <Row gutter={16} className="mt-4">
                <Col span={8}>
                  <AllRoofColors
                    setSelectedRoofColor={setSelectedRoofColor}
                    selectedRoofColor={selectedRoofColor}
                    roofColors={company.roofColors}
                  />
                  <Button type="primary" onClick={handleAddRoofColor} disabled={user.viewOnly}>
                    Add Roof Color
                  </Button>
                </Col>

                <Col span={8}>
                  <AllSidingColors
                    setSelectedSidingColor={setSelectedSidingColor}
                    selectedSidingColor={selectedSidingColor}
                    sidingColors={company.sidingColors}
                  />
                  <Button type="primary" onClick={handleAddSidingColor} disabled={user.viewOnly}>
                    Add Siding Color
                  </Button>
                </Col>

                <Col span={8}>
                  <AllTrimColors
                    setSelectedTrimColor={setSelectedTrimColor}
                    selectedTrimColor={selectedTrimColor}
                    trimColors={company.trimColors}
                  />
                  <Button type="primary" onClick={handleAddTrimColor} disabled={user.viewOnly}>
                    Add Trim Color
                  </Button>
                </Col>
              </Row>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default SettingsForm;
