import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import AddOnLaborContext from "../../../../../../context/addOnLabor/addOnLaborContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import AuthContext from "../../../../../../context/auth/authContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllAddonLaborsLabor = () => {
  const navigate = useNavigate();

  const addOnLaborContext = useContext(AddOnLaborContext);
  const addonCategoryContext = useContext(AddonCategoryContext);

  const { currentAddonCategory } = addonCategoryContext;
  const { getAddOnLabors, addOnLabors, setCurrentAddOnLabor, copyForShops } = addOnLaborContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    currentAddonCategory && getAddOnLabors(currentAddonCategory._id);
  }, []);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  // addonLaborLabors &&
  //   addonLaborLabors.map((addonLaborLabor) => {
  //     let newItem = {};
  //     newItem.Id = addonLaborLabor._id;
  //     newItem.Model = addonLaborLabor.addonLaborLaborModel.name;
  //     newItem.Width = addonLaborLabor.width;
  //     newItem.Length = addonLaborLabor.length;
  //     newItem.Retail_Price = addonLaborLabor.retailPrice;
  //     // newItem.Item_Id = item.item._id;
  //     itemsForExport.push(newItem);
  //   });

  // const exportFile = () => {
  //   const ws = utils.json_to_sheet(itemsForExport);
  //   const wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Data");
  //   writeFileXLSX(wb, "AddonLaborsLabor_Price.xlsx");
  // };

  const importFile = () => {
    // updateAllAddonLaborsLabor(newFile);
  };

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      // console.log(JSON.stringify(json, null, 2));
      // let jsonOrders = JSON.stringify(json);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  const columns = [
    // {
    //   title: "Name",
    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },

    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewAddonLabor(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },

    {
      title: "Labor",
      render: (record) => <Fragment>{record.standardLaborTotal}</Fragment>,
    },

    // {
    //   title: "Edit",
    //   width: "5%",
    //   render: (text, record) => (
    //     <span>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonLabor(record)}>
    //         View
    //       </button>
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddAddonLabor = () => {
    navigate("/company/addonlabor/form");
  };

  const handleViewAddonLabor = (addonLaborLabor) => {
    setCurrentAddOnLabor(addonLaborLabor);
    navigate("/company/addonlabor/form");
  };
  const handleCopy = () => {
    copyForShops();
  };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success float-start"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success float-start"
                  onClick={() => handleAddAddonLabor()}
                >
                  Add Add-on Labor
                </button>
                {/* <button className="mb-2 btn btn-outline-success float-end" onClick={() => handleCopy()}>
                  Copy For Shops
                </button> */}
                {/* {addonLaborLabors && (
                  <button className="mb-2 me-2 btn btn-outline-info float-end" onClick={() => exportFile()}>
                    Download AddonLaborsLabor For Price Update
                  </button>
                )} */}
              </div>
            </div>

            <Table
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={addOnLabors}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            {/* <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} /> */}
          </div>
          <div className="col-md-4">
            {/* <button className="mb-2 me-2 btn btn-outline-info" onClick={() => importFile()}>
              Upload Updated AddonLaborsLabor
            </button> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllAddonLaborsLabor;
