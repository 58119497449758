import React, { useState, useEffect, Fragment, useContext } from "react";
import { Table, Checkbox, Card, Input, Select } from "antd";
import styled from "styled-components";

import CompanyContext from "../../../../context/company/companyContext";
import ShopContext from "../../../../context/shop/shopContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import ShedModelContext from "../../../../context/shedModel/shedModelContext";
import ShedContext from "../../../../context/shed/shedContext";

const StyledInput = styled(Input)`
  width: 100%;
`;

const StyledSelect = styled(Select)`
  width: 100%;
  .ant-select-selector {
    border-radius: 6px !important;
    padding: 8px 12px !important;
    height: auto !important;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 16px;
`;

const FormGroup = styled.div`
  margin-bottom: 16px;
`;

const SectionTitle = styled.h4`
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 500;
`;

const InputLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  display: block;
`;

const PriceProjectionSheds = () => {
  const companyContext = useContext(CompanyContext);
  const { getCompany, company } = companyContext;

  const shopContext = useContext(ShopContext);
  const { getShops, shops, currentShop, setCurrentShop } = shopContext;

  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  const shedModelContext = useContext(ShedModelContext);
  const { shedModels, getShedModels } = shedModelContext;

  const shedContext = useContext(ShedContext);
  const { getSheds, sheds, clearSheds, getAllSheds, updateSuggestedRetailPrice, loadingSheds } = shedContext;

  const [eightWideRetailPercent, setEightWideRetailPercent] = useState();
  const [fourteenWideRetailPercent, setFourteenWideRetailPercent] = useState();
  const [newEightWideRetailPercent, setNewEightWideRetailPercent] = useState(60);
  const [newFourteenWideRetailPercent, setNewFourteenWideRetailPercent] = useState(60);
  const [shedStyle, setShedStyle] = useState("");
  const [newGrossProfitPercent, setNewGrossProfitPercent] = useState(15);
  const [zone, setZone] = useState("");
  const [discountChecked, setDiscountChecked] = useState(true);
  const [discountPercent, setDiscountPercent] = useState(0);
  const [storMorSpecific, setStorMorSpecific] = useState(false);

  const [dealerCommission, setDealerCommission] = useState();
  const [salesRepCommission, setSalesRepCommission] = useState();
  const [storMorOverhead, setStorMorOverhead] = useState();
  const [shopToLot, setShopToLot] = useState();
  const [lotToCustomer, setLotToCustomer] = useState();
  const [fourteenEscort, setFourteenEscort] = useState();
  const [sixteenEscort, setSixteenEscort] = useState();
  const [fuelSurchage, setFuelSurchage] = useState();

  const [shedCategory, setShedCategory] = useState();
  const [shedModel, setShedModel] = useState();

  const handleDiscountPercentChange = (e) => {
    setDiscountPercent(e.target.value);
  };
  const handleDiscountCheck = (e) => {
    setDiscountChecked(e.target.checked);
  };
  const handleStorMorSpecific = (e) => {
    setStorMorSpecific(e.target.checked);
  };

  useEffect(() => {
    getShops();
    getCompany();
    getShedCategorys();
    clearSheds();
  }, []);

  useEffect(() => {
    if (company) {
      setDealerCommission(company.dealerCommissionPercent);
      setSalesRepCommission(company.salesRepCommissionPercent);
      setStorMorOverhead(company.overheadPercentCompany);
      setShopToLot(company.shopToLotPercent);
      setLotToCustomer(company.lotToCustomerPercent);
      setFourteenEscort(company.fourteenWideEscort);
      setSixteenEscort(company.sixteenWideEscort);
      setFuelSurchage(company.fuelSurcharge);
    }
  }, [company]);

  const reactNameSelectChange = (shedName) => {
    setShedStyle(shedName.value);
  };

  const reactZoneSelectChange = (zone) => {
    setZone(zone.value);
  };

  const handleGrossProfitPercent = (e) => {
    setNewGrossProfitPercent(e.target.value);
  };

  const handleEightWidePercent = (e) => {
    setEightWideRetailPercent(e.target.value);
  };

  const handleFourteenWidePercent = (e) => {
    setFourteenWideRetailPercent(e.target.value);
  };
  const handleNewEightWidePercent = (e) => {
    setNewEightWideRetailPercent(e.target.value);
  };
  const handleNewFourteenWidePercent = (e) => {
    setNewFourteenWideRetailPercent(e.target.value);
  };

  const handleDealerCommission = (e) => {
    setDealerCommission(e.target.value);
  };

  const handleSalesRepCommission = (e) => {
    setSalesRepCommission(e.target.value);
  };
  const handleStorMorOverhead = (e) => {
    setStorMorOverhead(e.target.value);
  };
  const handleShopToLot = (e) => {
    setShopToLot(e.target.value);
  };
  const handleLotToCustomer = (e) => {
    setLotToCustomer(e.target.value);
  };
  const handleFourteenEscort = (e) => {
    setFourteenEscort(e.target.value);
  };
  const handleSixteenExcort = (e) => {
    setSixteenEscort(e.target.value);
  };

  const handleFuelSurcharge = (e) => {
    setFuelSurchage(e.target.value);
  };

  const shopOptions =
    shops &&
    shops.map((shop) => ({
      label: shop.name,
      value: shop._id,
    }));
  const handleShopChange = (shop) => {
    const selectedShop = shops.find((s) => s._id === shop.value);
    setCurrentShop(selectedShop);
    setEightWideRetailPercent(selectedShop.standardEightTenTwelveINVPercent);
    setFourteenWideRetailPercent(selectedShop.standardFourteenSixteenINVPercent);
  };

  const categoryOptions =
    shedCategorys &&
    shedCategorys.map((category) => ({
      label: category.name,
      value: category._id,
    }));
  const handleCategoryChange = (category) => {
    const selectedCategory = shedCategorys.find((c) => c._id === category.value);
    setShedCategory(selectedCategory);
    getShedModels(selectedCategory._id);
  };

  const modelOptions =
    shedModels &&
    shedModels.map((model) => ({
      label: model.name,
      value: model._id,
    }));
  const handleModelChange = (model) => {
    const selectedModel = shedModels.find((m) => m._id === model.value);
    setShedModel(selectedModel);
    getSheds(shedCategory._id, currentShop._id, selectedModel._id);
  };

  sheds && console.log(sheds);

  const desiredGpCalc = () => (
    <Card title="Desired GP Calculation" style={{ marginBottom: "16px" }}>
      <FormGroup>
        <InputLabel>Select Shop</InputLabel>
        <StyledSelect placeholder="Select Shop" options={shopOptions} onChange={(value) => handleShopChange({ value })} />
      </FormGroup>
      <FormGroup>
        <InputLabel>Select Category</InputLabel>
        <StyledSelect
          placeholder="Select Category"
          options={categoryOptions}
          onChange={(value) => handleCategoryChange({ value })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel>Select Model</InputLabel>
        <StyledSelect
          placeholder="Select Model"
          disabled={!shedCategory}
          options={modelOptions}
          onChange={(value) => handleModelChange({ value })}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel>Desired Gross Profit %</InputLabel>
        <StyledInput
          type="number"
          step=".01"
          value={newGrossProfitPercent}
          onChange={handleGrossProfitPercent}
          readOnly={discountChecked}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel>Current % of Retail for 8, 10, 12 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={eightWideRetailPercent} onChange={handleEightWidePercent} />
      </FormGroup>
      <FormGroup>
        <InputLabel>Current % of Retail for 14, 16 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={fourteenWideRetailPercent} onChange={handleFourteenWidePercent} />
      </FormGroup>
    </Card>
  );

  const projections = () => (
    <Card title="Projections" style={{ marginBottom: "16px" }}>
      <StyledCheckbox checked={discountChecked} onChange={handleDiscountCheck}>
        Figure New Retail based off Discount %
      </StyledCheckbox>
      <StyledCheckbox checked={storMorSpecific} onChange={handleStorMorSpecific}>
        100% Stor-Mor
      </StyledCheckbox>
      <FormGroup>
        <InputLabel>Discount %</InputLabel>
        <StyledInput
          readOnly={!discountChecked}
          type="number"
          step=".01"
          value={discountPercent}
          onChange={handleDiscountPercentChange}
        />
      </FormGroup>
      <FormGroup>
        <InputLabel>Proposed % of Retail for 8, 10, 12 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={newEightWideRetailPercent} onChange={handleNewEightWidePercent} />
      </FormGroup>
      <FormGroup>
        <InputLabel>Proposed % of Retail for 14, 16 Wide</InputLabel>
        <StyledInput type="number" step=".01" value={newFourteenWideRetailPercent} onChange={handleNewFourteenWidePercent} />
      </FormGroup>
    </Card>
  );

  const storMorExpenses = () => (
    <Card title="Stor-Mor Expenses" style={{ marginBottom: "16px" }}>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>Dealer Commission</InputLabel>
          <StyledInput type="number" step=".01" value={dealerCommission} onChange={handleDealerCommission} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>Sales Rep Commission</InputLabel>
          <StyledInput type="number" step=".01" value={salesRepCommission} onChange={handleSalesRepCommission} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>Overhead</InputLabel>
          <StyledInput type="number" step=".01" value={storMorOverhead} onChange={handleStorMorOverhead} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>Fuel Surcharge</InputLabel>
          <StyledInput type="number" step=".01" value={fuelSurchage} onChange={handleFuelSurcharge} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>Shop To Lot</InputLabel>
          <StyledInput type="number" step=".01" value={shopToLot} onChange={handleShopToLot} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>Lot to Customer</InputLabel>
          <StyledInput type="number" step=".01" value={lotToCustomer} onChange={handleLotToCustomer} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>16" Escort</InputLabel>
          <StyledInput type="number" step=".01" value={sixteenEscort} onChange={handleSixteenExcort} />
        </FormGroup>
        <FormGroup style={{ flex: 1, minWidth: "200px" }}>
          <InputLabel>14" Escort</InputLabel>
          <StyledInput type="number" step=".01" value={fourteenEscort} onChange={handleFourteenEscort} />
        </FormGroup>
      </div>
    </Card>
  );

  const figureSuggestedRetail = (record) => {
    if (discountChecked) {
      let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
      let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
      return roundedUp;
    } else {
      if (record.width < 14) {
        let totalCost = record.standardBoxTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newEightWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      } else {
        let totalCost = record.standardBoxTotalCost;
        let newRetailPrice = (totalCost / (1 - newGrossProfitPercent / 100) / (newFourteenWideRetailPercent / 100)).toFixed(2);
        let roundedUp = Math.ceil(newRetailPrice / 5) * 5;
        return roundedUp;
      }
    }
  };

  const figureNewGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theTotalCost = record.standardBoxTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theTotalCost = record.standardBoxTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const figureNewGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (newEightWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let theGrossProfit = figureNewGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newEightWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = newSuggestedRetail * (newFourteenWideRetailPercent / 100);
          let theGrossProfit = figureNewGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfitPercent = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theGrossProfit = figureCurrentGrossProfit(record);
        let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
        return theGrossProfitPercent;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          // let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          // let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      } else {
        if (record.width < 14) {
          // let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        } else {
          // let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theGrossProfit = figureCurrentGrossProfit(record);
          let theGrossProfitPercent = (theGrossProfit / percentOfRetail) * 100;
          return theGrossProfitPercent;
        }
      }
    }
  };

  const figureCurrentGrossProfit = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
        let theTotalCost = record.standardBoxTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      } else {
        let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
        let theTotalCost = record.standardBoxTotalCost;
        let theGrossProfit = percentOfRetail - theTotalCost;
        return theGrossProfit;
      }
    } else {
      if (discountChecked) {
        if (record.width < 14) {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          let newRetailPrice = record.retailPrice - record.retailPrice * (discountPercent / 100);
          let percentOfRetail = newRetailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      } else {
        if (record.width < 14) {
          // let newSuggestedRetail = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        } else {
          // let record.retailPrice = figureSuggestedRetail(record);
          let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
          let theTotalCost = record.standardBoxTotalCost;
          let theGrossProfit = percentOfRetail - theTotalCost;
          return theGrossProfit;
        }
      }
    }
  };

  const figurePercentOfRetail = (record) => {
    if (record.width < 14) {
      let percentOfRetail = record.retailPrice * (eightWideRetailPercent / 100);
      return percentOfRetail;
    } else {
      let percentOfRetail = record.retailPrice * (fourteenWideRetailPercent / 100);
      return percentOfRetail;
    }
  };

  const figureStorMorTotalCost = (record) => {
    if (record.width < 14) {
      let shopSalePrice = record.retailPrice * (eightWideRetailPercent / 100);
      let overhead = record.retailPrice * (storMorOverhead / 100);
      let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
      let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
      let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
      let theDealerCommission = record.retailPrice * (dealerCommission / 100);
      let totalCost =
        +shopSalePrice +
        +overhead +
        +theLotToCustomer +
        +theShopToLot +
        +theSalesRepCommission +
        +theDealerCommission +
        +fuelSurchage;
      return totalCost;
    } else if (record.width === 14) {
      let shopSalePrice = record.retailPrice * (fourteenWideRetailPercent / 100);
      let overhead = record.retailPrice * (storMorOverhead / 100);
      let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
      let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
      let escort = fourteenEscort;
      let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
      let theDealerCommission = record.retailPrice * (dealerCommission / 100);
      let totalCost =
        +shopSalePrice +
        +overhead +
        +theLotToCustomer +
        +theShopToLot +
        +theSalesRepCommission +
        +theDealerCommission +
        +escort +
        +fuelSurchage;
      return totalCost;
    } else if (record.width === 16) {
      let shopSalePrice = record.retailPrice * (fourteenWideRetailPercent / 100);
      let overhead = record.retailPrice * (storMorOverhead / 100);
      let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
      let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
      let escort = sixteenEscort;
      let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
      let theDealerCommission = record.retailPrice * (dealerCommission / 100);
      let totalCost =
        +shopSalePrice +
        +overhead +
        +theLotToCustomer +
        +theShopToLot +
        +theSalesRepCommission +
        +theDealerCommission +
        +escort +
        +fuelSurchage;
      return totalCost;
    } else {
      return 0;
    }
  };

  const newFigureStorMorTotalCost = (record) => {
    if (storMorSpecific) {
      if (record.width < 14) {
        let shopSalePrice = record.retailPrice * (newEightWideRetailPercent / 100);
        let overhead = record.retailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
        let theDealerCommission = record.retailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 14) {
        let shopSalePrice = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = record.retailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = fourteenEscort;
        let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
        let theDealerCommission = record.retailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 16) {
        let shopSalePrice = record.retailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = record.retailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = sixteenEscort;
        let theSalesRepCommission = record.retailPrice * (salesRepCommission / 100);
        let theDealerCommission = record.retailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else {
        return 0;
      }
    } else {
      let newRetailPrice = figureSuggestedRetail(record);
      if (record.width < 14) {
        let shopSalePrice = newRetailPrice * (newEightWideRetailPercent / 100);
        let overhead = newRetailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let theSalesRepCommission = newRetailPrice * (salesRepCommission / 100);
        let theDealerCommission = newRetailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 14) {
        let shopSalePrice = newRetailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = newRetailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = fourteenEscort;
        let theSalesRepCommission = newRetailPrice * (salesRepCommission / 100);
        let theDealerCommission = newRetailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else if (record.width === 16) {
        let shopSalePrice = newRetailPrice * (newFourteenWideRetailPercent / 100);
        let overhead = newRetailPrice * (storMorOverhead / 100);
        let theLotToCustomer = record.haulBase.haulBase * (lotToCustomer / 100);
        let theShopToLot = record.haulBase.haulBase * (shopToLot / 100);
        let escort = sixteenEscort;
        let theSalesRepCommission = newRetailPrice * (salesRepCommission / 100);
        let theDealerCommission = newRetailPrice * (dealerCommission / 100);
        let totalCost =
          +shopSalePrice +
          +overhead +
          +theLotToCustomer +
          +theShopToLot +
          +theSalesRepCommission +
          +theDealerCommission +
          +escort +
          +fuelSurchage;
        return totalCost;
      } else {
        return 0;
      }
    }
  };

  const figureStorMorCurrentGp = (record) => {
    let totalCost = figureStorMorTotalCost(record);
    let currentGp = record.retailPrice - totalCost;
    let currentGpPercent = (currentGp / record.retailPrice) * 100;
    return currentGpPercent;
  };

  const figureStorMorNewGp = (record) => {
    let totalCost = newFigureStorMorTotalCost(record);
    let suggestedRetail = figureSuggestedRetail(record);
    let newGp = suggestedRetail - totalCost;
    let newGpPercent = (newGp / suggestedRetail) * 100;
    return newGpPercent;
  };

  const columns = [
    {
      title: "Width",
      dataIndex: "width",
    },

    {
      title: "Length",
      dataIndex: "length",
    },

    {
      title: "Current Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#bdffb6",
            },
          },
          children: <div>$ {record.retailPrice.toLocaleString("en-US")}</div>,
        };
      },
    },
    {
      title: "Suggested Retail Price",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#a9ff9f",
            },
          },
          children: <div>${figureSuggestedRetail(record).toLocaleString("en-US")}</div>,
        };
      },
    },

    {
      title: "Shop Standard Current GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureCurrentGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },

    {
      title: "Shop Standard New GP %",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#f3ffa7",
            },
          },
          children: <div>{figureNewGrossProfitPercent(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
    // {
    //   title: "Standard New GP %",
    //   render: (text, record) => <span>{products && figureNewGrossProfitPercent(record).toLocaleString("en-US")}%</span>,
    // },
    {
      title: "Stor-Mor Total Cost",
      render: (text, record) => <span>${figureStorMorTotalCost(record).toLocaleString("en-US")}</span>,
    },
    {
      title: "Stor-Mor Projected Cost",
      render: (text, record) => <span>${newFigureStorMorTotalCost(record).toLocaleString("en-US")}</span>,
    },

    {
      title: "Stor-Mor Current GP",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#ffe0a7",
            },
          },
          children: <div>{figureStorMorCurrentGp(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
    {
      title: "Stor-Mor Projected GP",
      render(text, record) {
        return {
          props: {
            style: {
              background: "#ffe0a7",
            },
          },
          children: <div>{figureStorMorNewGp(record).toLocaleString("en-US")}%</div>,
        };
      },
    },
  ];

  const data = sheds && sheds;

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  return (
    <Fragment>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        <div style={{ flex: 1, minWidth: "300px" }}>{desiredGpCalc()}</div>
        <div style={{ flex: 1, minWidth: "300px" }}>{projections()}</div>
      </div>
      {storMorExpenses()}
      <Table
        rowClassName={() => "hover-row"}
        loading={!loadingSheds ? false : true}
        columns={columns}
        dataSource={data}
        rowKey="_id"
        onChange={onChange}
        scroll={{ y: "70vh" }}
      />
    </Fragment>
  );
};

export default PriceProjectionSheds;
