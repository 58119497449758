import React, { useState, useEffect, useRef } from "react";
import { Progress } from "antd";

function CountdownProgressBar({ loading }) {
  const totalDuration = 150; // Total duration in seconds (3 minutes)
  const [remainingTime, setRemainingTime] = useState(totalDuration);
  const intervalRef = useRef(null);

  useEffect(() => {
    if (loading) {
      // Reset remainingTime when loading starts
      setRemainingTime(totalDuration);
      // Start the countdown timer
      const startTime = Date.now();
      intervalRef.current = setInterval(() => {
        const elapsedTime = Math.floor((Date.now() - startTime) / 1000);
        const newRemainingTime = totalDuration - elapsedTime;

        if (newRemainingTime <= 0) {
          clearInterval(intervalRef.current);
          setRemainingTime(0);
        } else {
          setRemainingTime(newRemainingTime);
        }
      }, 1000);
    } else {
      // When loading is false, fill the progress bar
      clearInterval(intervalRef.current);
      setRemainingTime(0);
    }

    // Cleanup on component unmount or when loading changes
    return () => clearInterval(intervalRef.current);
  }, [loading, totalDuration]);

  // Calculate the percentage for the progress bar
  const percent = ((totalDuration - remainingTime) / totalDuration) * 100;

  // Format the remaining time as mm:ss
  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;
  const timeString = `${minutes}:${seconds.toString().padStart(2, "0")}`;

  if (!loading && remainingTime === 0) {
    // Do not render the progress bar when loading is false and the timer is complete
    return null;
  }

  return (
    <div style={{ width: "100%", margin: "0 auto", padding: "20px 0" }}>
      <Progress percent={percent} status={loading ? "active" : "normal"} showInfo={false} />
      <div style={{ textAlign: "center", marginTop: "8px" }}>
        {loading ? `Time Remaining: ${timeString}` : "Processing Complete"}
        <p>Please do not refresh and try to update again until finished.</p>
      </div>
    </div>
  );
}

export default CountdownProgressBar;
