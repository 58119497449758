import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddonParentCategoryContext from "../../../../../../context/addonParentCategory/addonParentCategoryContext";

import "antd/dist/antd.css";
import { Table, Modal } from "antd";

const AllAddonLaborParentCategories = () => {
  const navigate = useNavigate();

  const addOnParentCategoryContext = useContext(AddonParentCategoryContext);
  const { getAddonParentCategorys, addonParentCategorys, setCurrentAddonParentCategory, deleteAddonParentCategory } =
    addOnParentCategoryContext;

  useEffect(() => {
    getAddonParentCategorys();
  }, []);

  useEffect(() => {}, [addonParentCategorys]);

  const columns = [
    // {
    //   title: "Name",

    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewAddonParentCategory(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Id",
      render: (text, record) => {
        // do something like adding commas to the value or prefix
        return <span>{record._id}</span>;
      },
    },
    // {
    //   title: "Edit",
    //   width: "10%",
    //   render: (text, record) => (
    //     <span>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewAddonParentCategory(record)}>
    //         View
    //       </button>
    //       {/* <button
    //         className="btn btn-xs btn-outline-danger ms-2"
    //         type="button"
    //         onClick={() => handleDeleteAddonParentCategory(record)}
    //       >
    //         Delete
    //       </button> */}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddonParentCategory = (shedCategory) => {
    setCurrentAddonParentCategory(shedCategory);
    navigate("/company/addonlabor/parent-categories/categories");
  };

  // const handleEditShedCategory = (shedCategory) => {
  //   setCurrentShedCategory(shedCategory);
  //   navigate("/shedCategory/edit");
  // };

  // const handleDeleteAddonParentCategory = (addonParentCategory) => {
  //   console.log("Delete", addonParentCategory);
  //   deleteAddonParentCategory(addonParentCategory._id);
  // };

  // const [isAddonParentCategoryModalVisible, setIsAddonParentCategoryModalVisible] = useState(false);

  // const handleShowModal = () => {
  //   showAddonParentCategoryModal();
  // };

  // // roof modal
  // const showAddonParentCategoryModal = () => {
  //   setIsAddonParentCategoryModalVisible(true);
  // };
  // const handleAddonParentCategoryOk = () => {
  //   setIsAddonParentCategoryModalVisible(false);
  // };

  // const handleAddonParentCategoryCancel = () => {
  //   setIsAddonParentCategoryModalVisible(false);
  // };

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                {/* <button className="mb-2 btn btn-outline-success float-start" onClick={() => handleShowModal()}>
                  Add Addon Parent Category
                </button> */}
              </div>
            </div>

            {/* <Modal
              // title="Add User"
              visible={isAddonParentCategoryModalVisible}
              onOk={handleAddonParentCategoryOk}
              onCancel={handleAddonParentCategoryCancel}
              footer={null} // Remove the default footer buttons
              bodyStyle={{
                maxHeight: "50vh", // Adjust the height based on your needs
                overflowY: "auto",
              }}
            >
              <AddAddonParentCategory />
            </Modal> */}

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={addonParentCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllAddonLaborParentCategories;
