import React, { useState, useContext, useEffect } from "react";
import { Modal, Tag, Button, Space, Typography, DatePicker } from "antd";
import Select from "react-select";
import ShopContext from "../../../../context/shop/shopContext";
import ShedCategoryContext from "../../../../context/shedCategory/shedCategoryContext";
import OrderContext from "../../../../context/order/orderContext";
import moment from "moment";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

const { Title } = Typography;

// Styled components for custom styling
const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
    background-color: #f0f2f5;
  }
  .ant-modal-title {
    font-weight: 600;
  }
`;

const FilterContainer = styled.div`
  margin-bottom: 20px;
`;

const FilterTitle = styled(Title)`
  margin-bottom: 16px !important;
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker {
    font-family: "Arial", sans-serif;
    border: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }
  .react-datepicker__header {
    background-color: #1890ff;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding-top: 8px;
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name {
    color: white;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #1890ff;
    color: white;
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #40a9ff;
    color: white;
  }
  .react-datepicker__day:hover {
    background-color: #e6f7ff;
  }
  .react-datepicker__navigation {
    top: 8px;
  }
  .react-datepicker__navigation--previous {
    border-right-color: white;
  }
  .react-datepicker__navigation--next {
    border-left-color: white;
  }
`;

const DatePickerWrapper = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

const StyledSelect = styled(Select)`
  margin-bottom: 16px;
`;

const TagContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const allOrderTypes = [
  { value: "Customer", label: "Customer" },
  { value: "Inventory", label: "Inventory" },
];
const allOrderDates = [
  { value: "Date Ordered", label: "Date Ordered" },
  { value: "Date Finished", label: "Date Finished" },
];

function TopOrderFilter({ setEightPercent, setFourteenPercent }) {
  const shopContext = useContext(ShopContext);
  const { getShops, shops } = shopContext;
  const orderContext = useContext(OrderContext);
  const { getTop100Report } = orderContext;
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { shedCategorys, getShedCategorys } = shedCategoryContext;

  useEffect(() => {
    getShops();
    getShedCategorys();
    getTop100Report(filters);
  }, []);

  let shopOptions = shops && shops.map((shop) => ({ value: shop._id, label: shop.name }));
  let categoryOptions = shedCategorys && shedCategorys.map((category) => ({ value: category._id, label: category.name }));

  const [filters, setFilters] = useState({
    orderType: [],
    shops: [],
    categories: [],
    dates: [moment().subtract(1, "quarters").startOf("quarter"), moment().subtract(1, "quarters").endOf("quarter")],
    orderDate: allOrderDates[1],
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    getTop100Report(filters);
    console.log("this is the shops", filters.shops);
    if (filters.shops.length > 0) {
      const firstShop = filters.shops[0];
      const populatedShop = shops.find((shop) => shop._id === firstShop.value);

      if (populatedShop) {
        setEightPercent(populatedShop.standardEightTenTwelveNBPercent);
        setFourteenPercent(populatedShop.standardFourteenSixteenNBPercent);
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFilterChange = (filterKey, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  const clearFilter = (filterType, filterValue) => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      if (Array.isArray(newFilters[filterType])) {
        newFilters[filterType] = newFilters[filterType].filter((value) => value.value !== filterValue.value);
      } else {
        newFilters[filterType] = null;
      }
      getTop100Report(newFilters);
      return newFilters;
    });
  };

  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "quarters").startOf("quarter"),
    moment().subtract(1, "quarters").endOf("quarter"),
  ]);

  const handleDateChange = (dates) => {
    setFilters((prev) => ({
      ...prev,
      dates: dates,
    }));
  };

  return (
    <FilterContainer>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <Button type="primary" onClick={showModal} size="large">
          Select Filters
        </Button>

        <StyledModal
          title="Select Filters"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          width={700}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={handleOk}>
              Apply Filters
            </Button>,
          ]}
        >
          <FilterTitle level={5}>Date Range</FilterTitle>
          <DatePicker.RangePicker
            format="MM/DD/YYYY"
            className="mb-4 custom-datepicker"
            onChange={handleDateChange}
            value={filters.dates}
          />

          <FilterTitle level={5}>Order Date Type</FilterTitle>
          <StyledSelect
            options={allOrderDates}
            placeholder="Date Ordered"
            onChange={(value) => handleFilterChange("orderDate", value)}
            value={filters.orderDate}
          />

          <FilterTitle level={5}>Zones</FilterTitle>
          <StyledSelect
            options={categoryOptions}
            placeholder="Select Zones"
            onChange={(value) => handleFilterChange("categories", value)}
            isMulti
            isClearable
            value={filters.categories}
          />

          <FilterTitle level={5}>Order Type</FilterTitle>
          <StyledSelect
            options={allOrderTypes}
            placeholder="Select Order Type"
            onChange={(value) => handleFilterChange("orderType", value)}
            isMulti
            isClearable
            value={filters.orderType}
          />

          <FilterTitle level={5}>Shops</FilterTitle>
          <StyledSelect
            options={shopOptions}
            placeholder="Select Shops"
            onChange={(value) => handleFilterChange("shops", value)}
            isMulti
            isClearable
            value={filters.shops}
          />
        </StyledModal>

        <TagContainer>
          <Title level={5} style={{ marginRight: "16px", marginBottom: "0" }}>
            Active filters:
          </Title>
          {filters.dates[0] && filters.dates[1] && (
            <Tag
              color="blue"
              closable
              onClose={() => {
                handleDateChange([null, null]);
              }}
            >
              {`${filters.dates[0].format("MM-DD-YYYY")} to ${filters.dates[1].format("MM-DD-YYYY")}`}
            </Tag>
          )}
          {filters.orderDate && (
            <Tag color="green" closable onClose={() => clearFilter("orderDate", filters.orderDate)}>
              {filters.orderDate.label}
            </Tag>
          )}
          {filters.orderType.map((type) => (
            <Tag color="orange" key={type.value} closable onClose={() => clearFilter("orderType", type)}>
              {type.label}
            </Tag>
          ))}
          {filters.shops.map((shop) => (
            <Tag color="purple" key={shop.value} closable onClose={() => clearFilter("shops", shop)}>
              {shop.label}
            </Tag>
          ))}
          {filters.categories.map((category) => (
            <Tag color="cyan" key={category.value} closable onClose={() => clearFilter("categories", category)}>
              {category.label}
            </Tag>
          ))}
        </TagContainer>
      </Space>
    </FilterContainer>
  );
}

export default TopOrderFilter;
