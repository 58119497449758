import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ItemCategoryContext from "../../../../../../context/itemCategory/itemCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";
import ItemContext from "../../../../../../context/item/itemContext";
import AuthContext from "../../../../../../context/auth/authContext";
import { read, utils, writeFileXLSX } from "xlsx";

import "antd/dist/antd.css";
import { Table } from "antd";

const AllItemCategories = () => {
  const navigate = useNavigate();
  const itemCategoryContext = useContext(ItemCategoryContext);
  const { getItemCategorys, itemCategorys, setCurrentItemCategory } = itemCategoryContext;

  const itemContext = useContext(ItemContext);
  const { allItems, getItems, clearItems } = itemContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const authContext = useContext(AuthContext);
  const { user } = authContext;

  useEffect(() => {
    getItemCategorys();
    getItems();
    // clearItems when unmounting
    return () => {
      clearItems();
    };
  }, []);

  useEffect(() => {}, [itemCategorys]);

  const columns = [
    // {
    //   title: "Name",

    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewItemCategory(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },

    // {
    //   title: "Edit",
    //   width: "10%",
    //   render: (text, record) => (
    //     <span>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewItemCategory(record)}>
    //         View
    //       </button>
    //       {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditItemCategory(record)}>
    //         Edit
    //       </button> */}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleAddItemCategory = () => {
    navigate("/company/items/category/form");
  };

  const handleViewItemCategory = (itemCategory) => {
    clearItems();
    setCurrentItemCategory(itemCategory);
    navigate("/company/items/all");
  };

  const handleEditItemCategory = (itemCategory) => {
    setCurrentItemCategory(itemCategory);
    navigate("/company/itemcategory/edit");
  };

  const handleBack = () => {
    navigate(-1);
  };

  let itemsForExportByCategory = {};

  allItems &&
    allItems.forEach((item) => {
      // Group items by category
      if (!itemsForExportByCategory[item.category.name]) {
        itemsForExportByCategory[item.category.name] = [];
      }
      let newItem = {};
      newItem.Id = item._id;
      newItem.Name = item.name;
      newItem.Type = item.type;
      // Find the object in standardValues where the shop is the currentShop
      let standardValue = item.standardValues.find((value) => value.shop === currentShop._id);
      // Check if we found a standardValue before trying to access its properties
      if (standardValue) {
        newItem.Price = standardValue.subTotal;
        newItem.Freight = standardValue.freight;
      }
      itemsForExportByCategory[item.category.name].push(newItem);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each category
    for (let category in itemsForExportByCategory) {
      const ws = utils.json_to_sheet(itemsForExportByCategory[category]);
      const sanitizedCategory = sanitizeSheetName(category);
      utils.book_append_sheet(wb, ws, sanitizedCategory);
    }
    writeFileXLSX(wb, `${currentShop.name}_Standard_Items.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }

  return (
    <Fragment>
      <div>
        <div className="row">
          <div className="col">
            <div className="row">
              <div className="col-md-12">
                <button
                  className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-success"}
                  disabled={user.viewOnly}
                  // className="mb-2 btn btn-outline-success"
                  onClick={() => handleAddItemCategory()}
                >
                  Add Item Category
                </button>
                {allItems && allItems.length > 0 && (
                  <button
                    className={user.viewOnly ? "viewOnly" : "mb-2 ms-4 btn btn-primary"}
                    disabled={user.viewOnly}
                    // className="mb-2 ms-4 btn btn-primary"
                    onClick={() => exportFile()}
                  >
                    Export Excel
                  </button>
                )}

                <button
                  // className={user.viewOnly ? "viewOnly" : "mb-2 btn btn-outline-danger float-end"}
                  // disabled={user.viewOnly}
                  className="mb-2 btn btn-outline-danger float-end"
                  onClick={() => handleBack()}
                >
                  Back
                </button>
              </div>
            </div>

            <Table
              rowClassName={() => "hover-row"}
              // loading={!loadingProduct && productResults ? false : true}
              pagination={{ pageSize: 60 }}
              columns={columns}
              // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
              dataSource={itemCategorys}
              rowKey="_id"
              onChange={onChange}
              scroll={{ x: 1300, y: 2500 }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AllItemCategories;
