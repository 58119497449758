export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const GET_INVOICE_ALERT = "GET_INVOICE_ALERT";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const REMOVE_INVOICE_ALERT = "REMOVE_INVOICE_ALERT";
export const CLEAR_CURRENT_USER = "CLEAR_CURRENT_USER";
export const GET_STOCK_DATA = "GET_STOCK_DATA";

export const ALERT_ERROR = "ALERT_ERROR";

export const GET_LOGS = "GET_LOGS";
export const LOG_ERROR = "LOG_ERROR";
export const DELETE_LOG = "DELETE_LOG";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOAD_SHOP_USERS = "LOAD_SHOP_USERS";
export const COPY = "COPY";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

export const SET_SETTINGS = "SET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SHOP_SETTINGS = "GET_SHOP_SETTINGS";
export const GET_COMPANY_SETTINGS = "GET_COMPANY_SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const GET_BASE_STYLES = "GET_BASE_STYLES";

export const ADD_ITEM = "ADD_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";
export const CLEAR_ITEMS = "CLEAR_ITEMS";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
export const CLEAR_CURRENT_ITEM = "CLEAR_CURRENT_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const FILTER_ITEMS = "FILTER_ITEMS";
export const CLEAR_FILTER_ITEM = "CLEAR_FILTER_ITEM";
export const ITEM_ERROR = "ITEM_ERROR";
export const GET_ITEMS = "GET_ITEMS";
export const SET_LOADING_ITEM = "SET_LOADING_ITEM";
export const GET_STANDARD_ITEMS = "GET_STANDARD_ITEMS";
export const GET_ALL_ITEMS = "GET_ALL_ITEMS";

export const ADD_COMPONENT = "ADD_COMPONENT";
export const DELETE_COMPONENT = "DELETE_COMPONENT";
export const CLEAR_COMPONENTS = "CLEAR_COMPONENTS";
export const SET_CURRENT_COMPONENT = "SET_CURRENT_COMPONENT";
export const CLEAR_CURRENT_COMPONENT = "CLEAR_CURRENT_COMPONENT";
export const UPDATE_COMPONENT = "UPDATE_COMPONENT";
export const FILTER_COMPONENTS = "FILTER_COMPONENTS";
export const CLEAR_FILTER_COMPONENT = "CLEAR_FILTER_COMPONENT";
export const COMPONENT_ERROR = "COMPONENT_ERROR";
export const GET_COMPONENTS = "GET_COMPONENTS";
export const SET_LOADING_COMPONENT = "SET_LOADING_COMPONENT";

export const ADD_COMPONENT_CATEGORY = "ADD_COMPONENT_CATEGORY";
export const DELETE_COMPONENT_CATEGORY = "DELETE_COMPONENT_CATEGORY";
export const CLEAR_COMPONENT_CATEGORYS = "CLEAR_COMPONENT_CATEGORYS";
export const SET_CURRENT_COMPONENT_CATEGORY = "SET_CURRENT_COMPONENT_CATEGORY";
export const CLEAR_CURRENT_COMPONENT_CATEGORY = "CLEAR_CURRENT_COMPONENT_CATEGORY";
export const UPDATE_COMPONENT_CATEGORY = "UPDATE_COMPONENT_CATEGORY";
export const FILTER_COMPONENT_CATEGORYS = "FILTER_COMPONENT_CATEGORYS";
export const CLEAR_FILTER_COMPONENT_CATEGORY = "CLEAR_FILTER_COMPONENT_CATEGORY";
export const COMPONENT_CATEGORY_ERROR = "COMPONENT_CATEGORY_ERROR";
export const GET_COMPONENT_CATEGORYS = "GET_COMPONENT_CATEGORYS";
export const SET_LOADING_COMPONENT_CATEGORY = "SET_LOADING_COMPONENT_CATEGORY";

export const ADD_ADDON = "ADD_ADDON";
export const DELETE_ADDON = "DELETE_ADDON";
export const CLEAR_ADDONS = "CLEAR_ADDONS";
export const SET_CURRENT_ADDON = "SET_CURRENT_ADDON";
export const CLEAR_CURRENT_ADDON = "CLEAR_CURRENT_ADDON";
export const UPDATE_ADDON = "UPDATE_ADDON";
export const FILTER_ADDONS = "FILTER_ADDONS";
export const CLEAR_FILTER_ADDON = "CLEAR_FILTER_ADDON";
export const ADDON_ERROR = "ADDON_ERROR";
export const GET_ADDONS = "GET_ADDONS";
export const SET_LOADING_ADDON = "SET_LOADING_ADDON";
export const GET_ALL_ADDONS = "GET_ALL_ADDONS";

export const ADD_ADDON_CATEGORY = "ADD_ADDON_CATEGORY";
export const DELETE_ADDON_CATEGORY = "DELETE_ADDON_CATEGORY";
export const CLEAR_ADDON_CATEGORYS = "CLEAR_ADDON_CATEGORYS";
export const SET_CURRENT_ADDON_CATEGORY = "SET_CURRENT_ADDON_CATEGORY";
export const CLEAR_CURRENT_ADDON_CATEGORY = "CLEAR_CURRENT_ADDON_CATEGORY";
export const UPDATE_ADDON_CATEGORY = "UPDATE_ADDON_CATEGORY";
export const FILTER_ADDON_CATEGORYS = "FILTER_ADDON_CATEGORYS";
export const CLEAR_FILTER_ADDON_CATEGORY = "CLEAR_FILTER_ADDON_CATEGORY";
export const ADDON_CATEGORY_ERROR = "ADDON_CATEGORY_ERROR";
export const GET_ADDON_CATEGORYS = "GET_ADDON_CATEGORYS";
export const SET_LOADING_ADDON_CATEGORY = "SET_LOADING_ADDON_CATEGORY";

export const ADD_ADDONPARENT_CATEGORY = "ADD_ADDONPARENT_CATEGORY";
export const DELETE_ADDONPARENT_CATEGORY = "DELETE_ADDONPARENT_CATEGORY";
export const CLEAR_ADDONPARENT_CATEGORYS = "CLEAR_ADDONPARENT_CATEGORYS";
export const SET_CURRENT_ADDONPARENT_CATEGORY = "SET_CURRENT_ADDONPARENT_CATEGORY";
export const CLEAR_CURRENT_ADDONPARENT_CATEGORY = "CLEAR_CURRENT_ADDONPARENT_CATEGORY";
export const UPDATE_ADDONPARENT_CATEGORY = "UPDATE_ADDONPARENT_CATEGORY";
export const FILTER_ADDONPARENT_CATEGORYS = "FILTER_ADDONPARENT_CATEGORYS";
export const CLEAR_FILTER_ADDONPARENT_CATEGORY = "CLEAR_FILTER_ADDONPARENT_CATEGORY";
export const ADDONPARENT_CATEGORY_ERROR = "ADDONPARENT_CATEGORY_ERROR";
export const GET_ADDONPARENT_CATEGORYS = "GET_ADDONPARENT_CATEGORYS";
export const SET_LOADING_ADDONPARENT_CATEGORY = "SET_LOADING_ADDONPARENT_CATEGORY";

export const ADD_SHED_CATEGORY = "ADD_SHED_CATEGORY";
export const DELETE_SHED_CATEGORY = "DELETE_SHED_CATEGORY";
export const CLEAR_SHED_CATEGORYS = "CLEAR_SHED_CATEGORYS";
export const SET_CURRENT_SHED_CATEGORY = "SET_CURRENT_SHED_CATEGORY";
export const CLEAR_CURRENT_SHED_CATEGORY = "CLEAR_CURRENT_SHED_CATEGORY";
export const UPDATE_SHED_CATEGORY = "UPDATE_SHED_CATEGORY";
export const FILTER_SHED_CATEGORYS = "FILTER_SHED_CATEGORYS";
export const CLEAR_FILTER_SHED_CATEGORY = "CLEAR_FILTER_SHED_CATEGORY";
export const SHED_CATEGORY_ERROR = "SHED_CATEGORY_ERROR";
export const GET_SHED_CATEGORYS = "GET_SHED_CATEGORYS";
export const SET_LOADING_SHED_CATEGORY = "SET_LOADING_SHED_CATEGORY";

export const ADD_SHED_MODEL = "ADD_SHED_MODEL";
export const DELETE_SHED_MODEL = "DELETE_SHED_MODEL";
export const CLEAR_SHED_MODELS = "CLEAR_SHED_MODELS";
export const SET_CURRENT_SHED_MODEL = "SET_CURRENT_SHED_MODEL";
export const CLEAR_CURRENT_SHED_MODEL = "CLEAR_CURRENT_SHED_MODEL";
export const UPDATE_SHED_MODEL = "UPDATE_SHED_MODEL";
export const FILTER_SHED_MODELS = "FILTER_SHED_MODELS";
export const CLEAR_FILTER_SHED_MODEL = "CLEAR_FILTER_SHED_MODEL";
export const SHED_MODEL_ERROR = "SHED_MODEL_ERROR";
export const GET_SHED_MODELS = "GET_SHED_MODELS";
export const SET_LOADING_SHED_MODEL = "SET_LOADING_SHED_MODEL";

export const ADD_ITEM_CATEGORY = "ADD_ITEM_CATEGORY";
export const DELETE_ITEM_CATEGORY = "DELETE_ITEM_CATEGORY";
export const CLEAR_ITEM_CATEGORYS = "CLEAR_ITEM_CATEGORYS";
export const SET_CURRENT_ITEM_CATEGORY = "SET_CURRENT_ITEM_CATEGORY";
export const CLEAR_CURRENT_ITEM_CATEGORY = "CLEAR_CURRENT_ITEM_CATEGORY";
export const UPDATE_ITEM_CATEGORY = "UPDATE_ITEM_CATEGORY";
export const FILTER_ITEM_CATEGORYS = "FILTER_ITEM_CATEGORYS";
export const CLEAR_FILTER_ITEM_CATEGORY = "CLEAR_FILTER_ITEM_CATEGORY";
export const ITEM_CATEGORY_ERROR = "ITEM_CATEGORY_ERROR";
export const GET_ITEM_CATEGORYS = "GET_ITEM_CATEGORYS";
export const SET_LOADING_ITEM_CATEGORY = "SET_LOADING_ITEM_CATEGORY";

export const ADD_SHEDLABORMODEL = "ADD_SHEDLABORMODEL";
export const DELETE_SHEDLABORMODEL = "DELETE_SHEDLABORMODEL";
export const CLEAR_SHEDLABORMODELS = "CLEAR_SHEDLABORMODELS";
export const SET_CURRENT_SHEDLABORMODEL = "SET_CURRENT_SHEDLABORMODEL";
export const CLEAR_CURRENT_SHEDLABORMODEL = "CLEAR_CURRENT_SHEDLABORMODEL";
export const UPDATE_SHEDLABORMODEL = "UPDATE_SHEDLABORMODEL";
export const FILTER_SHEDLABORMODELS = "FILTER_SHEDLABORMODELS";
export const CLEAR_FILTER_SHEDLABORMODEL = "CLEAR_FILTER_SHEDLABORMODEL";
export const SHEDLABORMODEL_ERROR = "SHEDLABORMODEL_ERROR";
export const GET_SHEDLABORMODELS = "GET_SHEDLABORMODELS";
export const SET_LOADING_SHEDLABORMODEL = "SET_LOADING_SHEDLABORMODEL";

export const ADD_SHED = "ADD_SHED";
export const DELETE_SHED = "DELETE_SHED";
export const CLEAR_SHEDS = "CLEAR_SHEDS";
export const SET_CURRENT_SHED = "SET_CURRENT_SHED";
export const CLEAR_CURRENT_SHED = "CLEAR_CURRENT_SHED";
export const UPDATE_SHED = "UPDATE_SHED";
export const FILTER_SHEDS = "FILTER_SHEDS";
export const CLEAR_FILTER_SHED = "CLEAR_FILTER_SHED";
export const SHED_ERROR = "SHED_ERROR";
export const GET_SHEDS = "GET_SHEDS";
export const SET_SHEDS = "SET_SHEDS";
export const SET_LOADING_SHEDS = "SET_LOADING_SHEDS";
export const GET_SHEDS_BY_CATEGORY = "GET_SHEDS_BY_CATEGORY";

export const ADD_VENDOR = "ADD_VENDOR";
export const DELETE_VENDOR = "DELETE_VENDOR";
export const CLEAR_VENDORS = "CLEAR_VENDORS";
export const SET_CURRENT_VENDOR = "SET_CURRENT_VENDOR";
export const CLEAR_CURRENT_VENDOR = "CLEAR_CURRENT_VENDOR";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const FILTER_VENDORS = "FILTER_VENDORS";
export const CLEAR_FILTER_VENDOR = "CLEAR_FILTER_VENDOR";
export const VENDOR_ERROR = "VENDOR_ERROR";
export const GET_VENDORS = "GET_VENDORS";

export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const CLEAR_COMMENTS = "CLEAR_COMMENTS";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const COMMENT_ERROR = "COMMENT_ERROR";
export const GET_COMMENTS = "GET_COMMENTS";
export const SET_COMMENT_DRAWER = "SET_COMMENT_DRAWER";
export const GET_COMMENTS_FOR_ORDER = "GET_COMMENTS_FOR_ORDER";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const ADD_COMMENT_TO_CONVERSATION = "ADD_COMMENT_TO_CONVERSATION";
export const GET_SHOP_COMMENTS = "GET_SHOP_COMMENTS";
export const SET_LOADING_COMMENTS = "SET_LOADING_COMMENTS";
export const ADD_ORDER_COMMENT = "ADD_ORDER_COMMENT";

export const ADD_PAYMENT = "ADD_PAYMENT";
export const DELETE_PAYMENT = "DELETE_PAYMENT";
export const CLEAR_PAYMENTS = "CLEAR_PAYMENTS";
export const SET_CURRENT_PAYMENT = "SET_CURRENT_PAYMENT";
export const CLEAR_CURRENT_PAYMENT = "CLEAR_CURRENT_PAYMENT";
export const UPDATE_PAYMENT = "UPDATE_PAYMENT";
export const FILTER_PAYMENTS = "FILTER_PAYMENTS";
export const CLEAR_FILTER_PAYMENT = "CLEAR_FILTER_PAYMENT";
export const PAYMENT_ERROR = "PAYMENT_ERROR";
export const GET_PAYMENTS = "GET_PAYMENTS";
export const STATUS_CHANGE_PAYMENT = "STATUS_CHANGE_PAYMENT";
export const FILTER_PAYMENTS_BY_SHOP = "FILTER_PAYMENTS_BY_SHOP";
export const SET_LOADING_PAYMENTS = "SET_LOADING_PAYMENTS";

export const ADD_SHEDLABOR = "ADD_SHEDLABOR";
export const DELETE_SHEDLABOR = "DELETE_SHEDLABOR";
export const CLEAR_SHEDLABORS = "CLEAR_SHEDLABORS";
export const SET_CURRENT_SHEDLABOR = "SET_CURRENT_SHEDLABOR";
export const CLEAR_CURRENT_SHEDLABOR = "CLEAR_CURRENT_SHEDLABOR";
export const UPDATE_SHEDLABOR = "UPDATE_SHEDLABOR";
export const SHEDLABOR_ERROR = "SHEDLABOR_ERROR";
export const GET_SHEDLABORS = "GET_SHEDLABORS";
export const GET_ALL_SHEDLABORS = "GET_ALL_SHEDLABORS";

export const ADD_DEALER = "ADD_DEALER";
export const SET_DEALERS = "SET_DEALERS";
export const DELETE_DEALER = "DELETE_DEALER";
export const CLEAR_DEALERS = "CLEAR_DEALERS";
export const SET_CURRENT_DEALER = "SET_CURRENT_DEALER";
export const CLEAR_CURRENT_DEALER = "CLEAR_CURRENT_DEALER";
export const UPDATE_DEALER = "UPDATE_DEALER";
export const FILTER_DEALERS = "FILTER_DEALERS";
export const CLEAR_FILTER_DEALER = "CLEAR_FILTER_DEALER";
export const DEALER_ERROR = "DEALER_ERROR";
export const GET_DEALERS = "GET_DEALERS";

export const ADD_HAULBASE = "ADD_HAULBASE";
export const DELETE_HAULBASE = "DELETE_HAULBASE";
export const CLEAR_HAULBASES = "CLEAR_HAULBASES";
export const SET_CURRENT_HAULBASE = "SET_CURRENT_HAULBASE";
export const CLEAR_CURRENT_HAULBASE = "CLEAR_CURRENT_HAULBASE";
export const UPDATE_HAULBASE = "UPDATE_HAULBASE";
export const HAULBASE_ERROR = "HAULBASE_ERROR";
export const GET_HAULBASES = "GET_HAULBASES";

export const ADD_ADDON_LABOR = "ADD_ADDON_LABOR";
export const DELETE_ADDON_LABOR = "DELETE_ADDON_LABOR";
export const CLEAR_ADDON_LABORS = "CLEAR_ADDON_LABORS";
export const SET_CURRENT_ADDON_LABOR = "SET_CURRENT_ADDON_LABOR";
export const CLEAR_CURRENT_ADDON_LABOR = "CLEAR_CURRENT_ADDON_LABOR";
export const UPDATE_ADDON_LABOR = "UPDATE_ADDON_LABOR";
export const FILTER_ADDON_LABORS = "FILTER_ADDON_LABORS";
export const CLEAR_FILTER_ADDON_LABOR = "CLEAR_FILTER_ADDON_LABOR";
export const ADDON_LABOR_ERROR = "ADDON_LABOR_ERROR";
export const GET_ADDON_LABORS = "GET_ADDON_LABORS";
export const SET_LOADING_ADDON_LABOR = "SET_LOADING_ADDON_LABOR";
export const GET_STANDARD_ADDON_LABORS = "GET_STANDARD_ADDON_LABORS";

export const ADD_ORDER = "ADD_ORDER";
export const DELETE_ORDER = "DELETE_ORDER";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
export const CLEAR_CURRENT_ORDER = "CLEAR_CURRENT_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const FILTER_ORDERS = "FILTER_ORDERS";
export const CLEAR_FILTER_ORDER = "CLEAR_FILTER_ORDER";
export const ORDER_ERROR = "ORDER_ERROR";
export const GET_ORDERS = "GET_ORDERS";
export const STATUS_CHANGE_ORDER = "STATUS_CHANGE_ORDER";
export const SWITCH_LOADINGORDER = "SWITCH_LOADINGORDER";
export const SET_CURRENT_SHOP_ORDERS = "SET_CURRENT_SHOP_ORDERS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const SET_LOADING_ORDERS = "SET_LOADING_ORDERS";
export const GET_ORDER_COUNT = "GET_ORDER_COUNT";
export const CLEAR_ORDER_COUNT = "CLEAR_ORDER_COUNT";
export const SET_PAGINATION = "SET_PAGINATION";
export const SET_SEARCHED_ORDER = "SET_SEARCHED_ORDER";
export const CLEAR_SEARCHED_ORDER = "CLEAR_SEARCHED_ORDER";
export const SET_SERIAL_NUMBER_SEARCH = " SET_SERIAL_NUMBER_SEARCH";
export const GET_ORDERS_CHART = "GET_ORDERS_CHART";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const DELETE_DUP_ORDER = "DELETE_DUP_ORDER";
export const REVERT_UPDATE_ORDER = "REVERT_UPDATE_ORDER";
export const GET_TOP_ORDERS = "GET_TOP_ORDERS";
export const GET_REPORT_ORDERS = "GET_REPORT_ORDERS";

export const ADD_SHOP = "ADD_SHOP";
export const SET_SHOPS = "SET_SHOPS";
export const DELETE_SHOP = "DELETE_SHOP";
export const CLEAR_SHOPS = "CLEAR_SHOPS";
export const SET_CURRENT_SHOP = "SET_CURRENT_SHOP";
export const CLEAR_CURRENT_SHOP = "CLEAR_CURRENT_SHOP";
export const UPDATE_SHOP = "UPDATE_SHOP";
export const FILTER_SHOPS = "FILTER_SHOPS";
export const CLEAR_FILTER_SHOP = "CLEAR_FILTER_SHOP";
export const SHOP_ERROR = "SHOP_ERROR";
export const GET_SHOPS = "GET_SHOPS";

export const ADD_BASESTYLE = "ADD_BASESTYLE";
export const SET_BASESTYLES = "SET_BASESTYLES";
export const DELETE_BASESTYLE = "DELETE_BASESTYLE";
export const CLEAR_BASESTYLES = "CLEAR_BASESTYLES";
export const SET_CURRENT_BASESTYLE = "SET_CURRENT_BASESTYLE";
export const CLEAR_CURRENT_BASESTYLE = "CLEAR_CURRENT_BASESTYLE";
export const UPDATE_BASESTYLE = "UPDATE_BASESTYLE";
export const FILTER_BASESTYLES = "FILTER_BASESTYLES";
export const CLEAR_FILTER_BASESTYLE = "CLEAR_FILTER_BASESTYLE";
export const BASESTYLE_ERROR = "BASESTYLE_ERROR";
export const GET_BASESTYLES = "GET_BASESTYLES";

export const ADD_COMPANY = "ADD_COMPANY";
export const SET_COMPANYS = "SET_COMPANYS";
export const DELETE_COMPANY = "DELETE_COMPANY";
export const CLEAR_COMPANYS = "CLEAR_COMPANYS";
export const SET_CURRENT_COMPANY = "SET_CURRENT_COMPANY";
export const CLEAR_CURRENT_COMPANY = "CLEAR_CURRENT_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const FILTER_COMPANYS = "FILTER_COMPANYS";
export const CLEAR_FILTER_COMPANY = "CLEAR_FILTER_COMPANY";
export const COMPANY_ERROR = "COMPANY_ERROR";
export const GET_COMPANYS = "GET_COMPANYS";

export const ADD_INVOICE = "ADD_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const CLEAR_INVOICES = "CLEAR_INVOICES";
export const SET_CURRENT_INVOICE = "SET_CURRENT_INVOICE";
export const CLEAR_CURRENT_INVOICE = "CLEAR_CURRENT_INVOICE";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const SET_FILTERED_INVOICES = "SET_FILTERED_INVOICES";
export const CLEAR_FILTERED_INVOICES = "CLEAR_FILTERED_INVOICES";
export const INVOICE_ERROR = "INVOICE_ERROR";
export const GET_INVOICES = "GET_INVOICES";
export const STATUS_CHANGE_INVOICE = "STATUS_CHANGE_INVOICE";
export const GET_INVOICE_BY_SHOP_STATUS = "GET_INVOICE_BY_SHOP_STATUS";
export const SET_SEARCHED_INVOICE = "SET_SEARCHED_INVOICE";
export const CLEAR_SEARCHED_INVOICE = "CLEAR_SEARCHED_INVOICE";
export const SWITCH_LOADINGINVOICE = "SWITCH_LOADINGINVOICE";
export const SET_INVOICE_LOADING = "SET_INVOICE_LOADING";
export const CLEAR_FILTER_INVOICES_BY_SHOP = "CLEAR_FILTER_INVOICES_BY_SHOP";
export const SET_SHOP_FOR_FILTER = "SET_SHOP_FOR_FILTER";
export const SET_INVOICE_NUMBER_SEARCH = "SET_INVOICE_NUMBER_SEARCH";
export const SET_CUT_OFF_DATE = "SET_CUT_OFF_DATE";
export const SET_INVOICE_PAGINATION = "SET_INVOICE_PAGINATION";
export const GET_INVOICE_COUNT = "GET_INVOICE_COUNT";
export const CLEAR_INVOICE_COUNT = "CLEAR_INVOICE_COUNT";
export const SET_INVOICE_IS_CANCELED = "SET_INVOICE_IS_CANCELED";
export const GET_ADJUSTED_INVOICES = "GET_ADJUSTED_INVOICES";
export const UPDATE_ADJUSTED_INVOICE = "UPDATE_ADJUSTED_INVOICE";
export const GET_INVOICES_CHART = "GET_INVOICES_CHART";
export const UPDATE_INVOICE_ORDER = "UPDATE_INVOICE_ORDER";
export const GET_REPORT_INVOICES = "GET_REPORT_INVOICES";

export const ADD_METALORDER = "ADD_METALORDER";
export const DELETE_METALORDER = "DELETE_METALORDER";
export const CLEAR_METALORDERS = "CLEAR_METALORDERS";
export const SET_CURRENT_METALORDER = "SET_CURRENT_METALORDER";
export const CLEAR_CURRENT_METALORDER = "CLEAR_CURRENT_METALORDER";
export const UPDATE_METALORDER = "UPDATE_METALORDER";
export const FILTER_METALORDERS = "FILTER_METALORDERS";
export const CLEAR_FILTER_METALORDER = "CLEAR_FILTER_METALORDER";
export const METALORDER_ERROR = "METALORDER_ERROR";
export const GET_METALORDERS = "GET_METALORDERS";

export const ADD_MATERIALORDER = "ADD_MATERIALORDER";
export const DELETE_MATERIALORDER = "DELETE_MATERIALORDER";
export const CLEAR_MATERIALORDERS = "CLEAR_MATERIALORDERS";
export const SET_CURRENT_MATERIALORDER = "SET_CURRENT_MATERIALORDER";
export const CLEAR_CURRENT_MATERIALORDER = "CLEAR_CURRENT_MATERIALORDER";
export const UPDATE_MATERIALORDER = "UPDATE_MATERIALORDER";
export const FILTER_MATERIALORDERS = "FILTER_MATERIALORDERS";
export const CLEAR_FILTER_MATERIALORDER = "CLEAR_FILTER_MATERIALORDER";
export const MATERIALORDER_ERROR = "MATERIALORDER_ERROR";
export const GET_MATERIALORDERS = "GET_MATERIALORDERS";
export const GET_RECIEVED_MATERIALORDERS = "GET_RECIEVED_MATERIALORDERS";
export const SET_LOADING_MATERIALORDERS = "SET_LOADING_MATERIALORDERS";

export const ADD_ANNOUNCEMENT = "ADD_ANNOUNCEMENT";
export const DELETE_ANNOUNCEMENT = "DELETE_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT = "UPDATE_ANNOUNCEMENT";
export const ANNOUNCEMENT_ERROR = "ANNOUNCEMENT_ERROR";
export const GET_ANNOUNCEMENTS = "GET_ANNOUNCEMENTS";
export const SET_CURRENT_ANNOUNCEMENT = "SET_CURRENT_ANNOUNCEMENT";
export const CLEAR_CURRENT_ANNOUNCEMENT = "CLEAR_CURRENT_ANNOUNCEMENT";
export const UPDATE_ANNOUNCEMENT_READ = "UPDATE_ANNOUNCEMENT_READ";
export const GET_ANNOUNCEMENTS_UNREAD = "GET_ANNOUNCEMENTS_UNREAD";

export const ADD_REPAIRINVOICE = "ADD_REPAIRINVOICE";
export const DELETE_REPAIRINVOICE = "DELETE_REPAIRINVOICE";
export const CLEAR_REPAIRINVOICES = "CLEAR_REPAIRINVOICES";
export const SET_CURRENT_REPAIRINVOICE = "SET_CURRENT_REPAIRINVOICE";
export const CLEAR_CURRENT_REPAIRINVOICE = "CLEAR_CURRENT_REPAIRINVOICE";
export const UPDATE_REPAIRINVOICE = "UPDATE_REPAIRINVOICE";
export const SET_FILTERED_REPAIRINVOICES = "SET_FILTERED_REPAIRINVOICES";
export const CLEAR_FILTERED_REPAIRINVOICES = "CLEAR_FILTERED_REPAIRINVOICES";
export const REPAIRINVOICE_ERROR = "REPAIRINVOICE_ERROR";
export const GET_REPAIRINVOICES = "GET_REPAIRINVOICES";
export const STATUS_CHANGE_REPAIRINVOICE = "STATUS_CHANGE_REPAIRINVOICE";
export const GET_REPAIRINVOICE_BY_SHOP_STATUS = "GET_REPAIRINVOICE_BY_SHOP_STATUS";
export const SET_SEARCHED_REPAIRINVOICE = "SET_SEARCHED_REPAIRINVOICE";
export const CLEAR_SEARCHED_REPAIRINVOICE = "CLEAR_SEARCHED_REPAIRINVOICE";
export const SWITCH_LOADINGREPAIRINVOICE = "SWITCH_LOADINGREPAIRINVOICE";
export const SET_REPAIRINVOICE_LOADING = "SET_REPAIRINVOICE_LOADING";
export const CLEAR_FILTER_REPAIRINVOICES_BY_SHOP = "CLEAR_FILTER_REPAIRINVOICES_BY_SHOP";
// export const SET_SHOP_FOR_FILTER = "SET_SHOP_FOR_FILTER";
export const SET_REPAIRINVOICE_NUMBER_SEARCH = "SET_REPAIRINVOICE_NUMBER_SEARCH";
// export const SET_CUT_OFF_DATE = "SET_CUT_OFF_DATE";
export const SET_REPAIRINVOICE_PAGINATION = "SET_REPAIRINVOICE_PAGINATION";
export const GET_REPAIRINVOICE_COUNT = "GET_REPAIRINVOICE_COUNT";
export const CLEAR_REPAIRINVOICE_COUNT = "CLEAR_REPAIRINVOICE_COUNT";
export const SET_REPAIRINVOICE_IS_CANCELED = "SET_REPAIRINVOICE_IS_CANCELED";
export const GET_ADJUSTED_REPAIRINVOICES = "GET_ADJUSTED_REPAIRINVOICES";
export const UPDATE_ADJUSTED_REPAIRINVOICE = "UPDATE_ADJUSTED_REPAIRINVOICE";
export const GET_REPAIRINVOICES_CHART = "GET_REPAIRINVOICES_CHART";
export const UPDATE_REPAIRINVOICE_ORDER = "UPDATE_REPAIRINVOICE_ORDER";
