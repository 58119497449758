import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { read, utils, writeFileXLSX } from "xlsx";

import AddOnContext from "../../../../../../context/addon/addOnContext";
import AddonCategoryContext from "../../../../../../context/addonCategory/addonCategoryContext";
import ShopContext from "../../../../../../context/shop/shopContext";

import "antd/dist/antd.css";
import { Table, Button } from "antd";

const ShopAllAddons = () => {
  const navigate = useNavigate();

  const addOnContext = useContext(AddOnContext);
  const addonCategoryContext = useContext(AddonCategoryContext);

  const { currentAddonCategory } = addonCategoryContext;
  const { getAddOns, addOns, setCurrentAddOn } = addOnContext;

  const shopContext = useContext(ShopContext);
  const { currentShop, getShop } = shopContext;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    if (currentAddonCategory && currentShop) {
      setLoading(true);
      getAddOns(currentAddonCategory._id, currentShop._id).finally(() => setLoading(false));
    }
  }, [currentShop, currentAddonCategory]);

  const [newFile, setNewFile] = useState("");
  let itemsForExport = [];

  const importFile = () => {
    // updateAllAddons(newFile);
  };

  const uploadFile = (e) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet);
      setNewFile(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };

  function shopPrice(addon) {
    return addon.retailPrice * (addon.shopPercent / 100);
  }

  const columns = [
    // {
    //   title: "Name",
    //   width: "20%",
    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewAddon(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    {
      title: "Retail Price",
      render: (record) => (
        <Fragment>
          {record.retailPrice.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Fragment>
      ),
    },
    {
      title: "Shop Percent",
      render: (record) => <Fragment>{record.shopPercent}</Fragment>,
    },
    {
      title: "Shop Price",
      render: (record) => (
        <Fragment>
          {shopPrice(record).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </Fragment>
      ),
    },
    {
      title: "Shop Cost",
      render: (record) => (
        <Fragment>
          {record.addOnShopTotalCost &&
            record.addOnShopTotalCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
        </Fragment>
      ),
    },
    {
      title: "Gross Profit %",
      render: (record) => (
        <Fragment>
          {record &&
            ((shopPrice(record) - record.addOnShopTotalCost) / shopPrice(record)).toLocaleString("en-US", {
              style: "percent",
            })}
        </Fragment>
      ),
    },
    // {
    //   title: "Edit",
    //   width: "5%",
    //   render: (text, record) => (
    //     <span>
    //       <Button type="primary" size="small" onClick={() => handleViewAddon(record)}>
    //         View
    //       </Button>
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewAddon = (addon) => {
    setCurrentAddOn(addon);
    navigate("/shop/addon/form");
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12">
          <Button className="mb-2 float-end" type="danger" onClick={handleBack}>
            Back
          </Button>
        </div>
      </div>
      <Table
        rowClassName={() => "hover-row"}
        loading={loading}
        pagination={{ pageSize: 60 }}
        columns={columns}
        dataSource={addOns}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllAddons;
