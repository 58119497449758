import React, { Fragment, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ItemCategoryContext from "../../../../../../context/itemCategory/itemCategoryContext";
import { read, utils, writeFileXLSX } from "xlsx";
import ShopContext from "../../../../../../context/shop/shopContext";
import ItemContext from "../../../../../../context/item/itemContext";

import "antd/dist/antd.css";
import { Table } from "antd";

const ShopAllItemCategories = () => {
  const navigate = useNavigate();

  const itemCategoryContext = useContext(ItemCategoryContext);
  const { getItemCategorys, itemCategorys, setCurrentItemCategory } = itemCategoryContext;

  const shopContext = useContext(ShopContext);
  const { currentShop } = shopContext;

  const itemContext = useContext(ItemContext);
  const { getItems, allItems, clearItems, updateShopItems } = itemContext;

  useEffect(() => {
    getItemCategorys();
    getItems();
    // clear items when unmounting
    return () => {
      clearItems();
    };
  }, []);

  useEffect(() => {}, [itemCategorys]);

  const columns = [
    // {
    //   title: "Name",

    //   render: (record) => <Fragment>{record.name}</Fragment>,
    // },
    {
      title: "Name",
      render: (record) => (
        <Fragment>
          <span
            onClick={() => handleViewItemCategory(record)}
            style={{
              color: "#007bff",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            {record.name}
          </span>
        </Fragment>
      ),
    },
    // {
    //   title: "Edit",
    //   width: "10%",
    //   render: (text, record) => (
    //     <span>
    //       <button className="btn btn-xs btn-outline-primary" type="button" onClick={() => handleViewItemCategory(record)}>
    //         View
    //       </button>
    //       {/* <button className="btn btn-xs btn-outline-danger ms-2" type="button" onClick={() => handleEditItemCategory(record)}>
    //         Edit
    //       </button> */}
    //     </span>
    //   ),
    // },
  ];

  function onChange(pagination, filters, sorter, extra) {
    console.log("params", pagination, filters, sorter, extra);
  }

  const handleViewItemCategory = (itemCategory) => {
    setCurrentItemCategory(itemCategory);
    navigate("/shop/items/all");
  };

  let itemsForExportByCategory = {};

  allItems &&
    allItems.forEach((item) => {
      // Group items by category
      if (!itemsForExportByCategory[item.category.name]) {
        itemsForExportByCategory[item.category.name] = [];
      }
      let newItem = {};
      newItem.Id = item._id;
      newItem.Name = item.name;
      newItem.Type = item.type;
      // Find the object in shopValues where the shop is the currentShop
      let shopValue = item.shopValues.find((value) => value.shop === currentShop._id);
      // Check if we found a shopValue before trying to access its properties
      if (shopValue) {
        let onYardQuantity = shopValue.quantityInRecieved + shopValue.quantityInCurrent;
        newItem.Price = shopValue.subTotal;
        newItem.Freight = shopValue.freight;
        newItem.Inventory = shopValue.inventoryItem;
        newItem.Ordered_Quantity = shopValue.quantityOnOrder;
        newItem.Recieved_Quantity = shopValue.quantityInRecieved;
        newItem.Current_Quantity = shopValue.quantityInCurrent;
        newItem.Total_On_Yard_Quantity = onYardQuantity;
        newItem.Physical_Count = 0;
      }
      itemsForExportByCategory[item.category.name].push(newItem);
    });

  const exportFile = () => {
    const wb = utils.book_new();
    // Create a new worksheet for each category
    for (let category in itemsForExportByCategory) {
      const ws = utils.json_to_sheet(itemsForExportByCategory[category]);
      const sanitizedCategory = sanitizeSheetName(category);
      utils.book_append_sheet(wb, ws, sanitizedCategory);
    }
    writeFileXLSX(wb, `Items.xlsx`);
  };

  function sanitizeSheetName(name) {
    // Replace invalid characters with underscore
    return name.replace(/[:\\/?*[\]]/g, "_");
  }

  const [newFile, setNewFile] = useState("");

  const uploadFile = (e) => {
    // const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = read(data, { type: "array" });
      // Initialize an empty array to hold all items
      let allItems = [];
      // Iterate over all sheets in the workbook
      workbook.SheetNames.forEach((sheetName) => {
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        // Append items from this sheet to the allItems array
        allItems = [...allItems, ...json];
      });
      setNewFile(allItems);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  };
  const importFile = () => {
    updateShopItems(newFile);
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-2">
          {allItems && allItems.length > 0 && (
            <button className="mb-2 ms-4 btn btn-primary" onClick={() => exportFile()}>
              Export Excel
            </button>
          )}
        </div>
        <div className="col-md-3">
          <input type="file" name="upload" id="file" className="form-control" onChange={uploadFile} />
        </div>

        <div className="col-md-2">
          <button className="mb-2 me-2 btn btn-primary" onClick={() => importFile()}>
            Import Excel
          </button>
        </div>
      </div>

      <Table
        rowClassName={() => "hover-row"}
        // loading={false : true}
        pagination={{ pageSize: 60 }}
        columns={columns}
        // dataSource={filteredProduct && filteredProduct !== null ? filteredProduct : productResults && productResults}
        dataSource={itemCategorys}
        rowKey="_id"
        onChange={onChange}
        scroll={{ x: 1300, y: 2500 }}
      />
    </Fragment>
  );
};

export default ShopAllItemCategories;
