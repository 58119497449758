import React, { useEffect, useContext } from "react";
import ItemContext from "../../../../../../context/item/itemContext";
import ComponentContext from "../../../../../../context/component/componentContext";
import ComponentCategoryContext from "../../../../../../context/componentCategory/componentCategoryContext";
import ShedCategoryContext from "../../../../../../context/shedCategory/shedCategoryContext";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import ItemArray from "./ComponentFormItemArr";
import StandardItemArray from "./ComponentFormItemArrCompany";
import { Form, Input, Button, Card, Typography, Row, Col } from "antd";

const { Title } = Typography;

const defaultValues = {
  name: "",
  category: null,
  standardItems: [
    {
      item: {},
    },
  ],
};

const ShopComponentForm = ({ setIsModalVisible, isModalVisible }) => {
  const navigate = useNavigate();
  const itemContext = useContext(ItemContext);
  const { getItems, allItems, clearItems } = itemContext;
  const componentContext = useContext(ComponentContext);
  const { addComponent, currentComponent, clearCurrentComponent, updateShopComponent } = componentContext;
  const componentCategoryContext = useContext(ComponentCategoryContext);
  const { currentComponentCategory } = componentCategoryContext;
  const shedCategoryContext = useContext(ShedCategoryContext);
  const { currentShedCategory } = shedCategoryContext;

  const { control, register, handleSubmit, getValues, errors, setValue, watch, reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    currentComponent && reset(currentComponent);
    currentComponentCategory && setValue("category", currentComponentCategory._id);
    currentShedCategory && setValue("shedCategory", currentShedCategory._id);
    getItems();
    return () => {
      clearItems();
    };
  }, []);

  const onSubmit = (data) => {
    if (!currentComponent) {
      addComponent(data);
    } else {
      updateShopComponent(data);
      if (setIsModalVisible) {
        setIsModalVisible(false);
      } else {
        navigate(-1);
      }
      clearCurrentComponent();
    }
  };

  const setExit = () => {
    navigate(-1);
    clearCurrentComponent();
  };

  return (
    <div className="container-fluid pb-4">
      <Row justify="center">
        <Col span={24}>
          {!isModalVisible && (
            <Button type="danger" className="float-end" onClick={setExit}>
              Exit
            </Button>
          )}
        </Col>
      </Row>
      <Row justify="center" className="mt-4">
        <Col span={20}>
          <Card style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.1)", border: "1px solid #e8e8e8", borderRadius: "8px" }}>
            <Title level={4} className="text-center">
              {currentComponent ? "Update Component" : "Add Component"}
            </Title>
            <Form layout="vertical" onFinish={handleSubmit(onSubmit)}>
              <Form.Item label="Name">
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                  rules={{ required: true }}
                />
              </Form.Item>
              <Title level={4} className="text-center">
                Standard Items
              </Title>
              <StandardItemArray
                {...{
                  control,
                  watch,
                  register,
                  defaultValues,
                  getValues,
                  setValue,
                  errors,
                  getValues,
                  allItems,
                }}
              />
              <Form.Item>
                <Button type="primary" htmlType="submit" className="float-end">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ShopComponentForm;
